import { combineReducers } from 'redux'

import {
  MASTER_GET_DETAIL_PROFILE_CUSTOM,
  MASTER_GET_DETAIL_PROFILE_CUSTOM_FAILED,
  MASTER_GET_DETAIL_PROFILE_CUSTOM_RESET,
  MASTER_GET_DETAIL_PROFILE_CUSTOM_SUCCESS,
  MASTER_GET_LIST_PROFILE_CUSTOM,
  MASTER_GET_LIST_PROFILE_CUSTOM_FAILED,
  MASTER_GET_LIST_PROFILE_CUSTOM_RESET,
  MASTER_GET_LIST_PROFILE_CUSTOM_SUCCESS,
  MASTER_SYNC_PROFILE_CUSTOM,
  MASTER_SYNC_PROFILE_CUSTOM_FAILED,
  MASTER_SYNC_PROFILE_CUSTOM_RESET,
  MASTER_SYNC_PROFILE_CUSTOM_SUCCESS,
  MASTER_UPDATE_PROFILE_CUSTOM,
  MASTER_UPDATE_PROFILE_CUSTOM_FAILED,
  MASTER_UPDATE_PROFILE_CUSTOM_SUCCESS,
  MASTER_CANCEL_REGISTER_ROUTE,
  MASTER_CANCEL_REGISTER_ROUTE_SUCCESS,
  MASTER_CANCEL_REGISTER_ROUTE_FAILED,
} from '../actions/profile-custom'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listProfileCustom(
  state = {
    isLoading: false,
    data: [],
    total: 0,
    totalUnRegisterRoute: 0,
    totalWorking: 0,
    count: 0,
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_PROFILE_CUSTOM:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_PROFILE_CUSTOM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      }
    case MASTER_GET_LIST_PROFILE_CUSTOM_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_LIST_PROFILE_CUSTOM_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function detailProfileCustom(state = { isLoading: false, data: {} }, action) {
  switch (action.type) {
    case MASTER_GET_DETAIL_PROFILE_CUSTOM:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_DETAIL_PROFILE_CUSTOM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.data,
      }
    case MASTER_GET_DETAIL_PROFILE_CUSTOM_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_GET_DETAIL_PROFILE_CUSTOM_RESET:
      return { isLoading: false, data: {} }
    default:
      return {
        ...state,
      }
  }
}

function syncProfileCustom(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_PROFILE_CUSTOM:
      return { ...state, isLoading: true }
    case MASTER_SYNC_PROFILE_CUSTOM_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_PROFILE_CUSTOM_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_PROFILE_CUSTOM_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

function updateProfileCustom(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_UPDATE_PROFILE_CUSTOM:
      return { ...state, isLoading: true }
    case MASTER_UPDATE_PROFILE_CUSTOM_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_UPDATE_PROFILE_CUSTOM_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

function cancelRegisterRoute(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_CANCEL_REGISTER_ROUTE:
      return { ...state, isLoading: true }
    case MASTER_CANCEL_REGISTER_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_CANCEL_REGISTER_ROUTE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  listProfileCustom,
  detailProfileCustom,
  syncProfileCustom,
  updateProfileCustom,
  cancelRegisterRoute,
})
