import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { apiGetListCatShiftList } from '~/modules/master/redux/apis/cat-shift'
import { STATUS_VALUE } from '~/modules/master/constants'
import { CAT_SHIFT_LTF_CODE } from '../../report-all/constants'
import { apiGetListOrgStructureList } from '~/modules/master/redux/apis/org-structure'
import { convertFilterParams } from '~/utils'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Field.TextField
              name="keyWord"
              labelWidth={70}
              placeholder={t('common.inputKeyWord')}
              inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
              endAdornment={
                <InputAdornment position="end" sx={{ mr: 1 }}>
                  <Icon name="search" />
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={3}>
          <Field.TextField
              name="route"
              // label={t('attendance.rosterCalendar.routeName')}
              placeholder={t('attendance.rosterCalendar.routeName')}
              inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
              labelWidth={70}
            />
          </Grid>

          <Grid item xs={3}>
              <Field.Autocomplete
                name="codeShift"
                placeholder={t('reportBusinessTravelNotCheckIn.filter.shift')}
                labelWidth={70}
                asyncRequest={(s) =>
                  apiGetListCatShiftList({
                    keyword: s,
                    limit: ASYNC_SEARCH_LIMIT,
                    filter: convertFilterParams({
                      codes: CAT_SHIFT_LTF_CODE.join(','),
                    }),
                  })
                }
                asyncRequestHelper={(res) => res?.data?.items}
                getOptionLabel={(option) => option.name}
                getOptionValue={(opt) => opt?.id}
                isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
              />
            </Grid>

          <Grid item xs={3}>
            <Field.DateRangePicker
              name="dateWork"
              labelWidth={70}
              placeholder={t('attendanceNotExcludingHoliday.filter.workingDay')}
            />
          </Grid>
          <Grid item xs={3}>
            <Field.Autocomplete
              name="orgStructureIds"
              placeholder={t('profileCustom.orgStructure')}
              labelWidth={70}
              asyncRequest={(s) =>
                apiGetListOrgStructureList({
                  keyword: s,
                  limit: ASYNC_SEARCH_LIMIT,
                  filter: convertFilterParams({
                    isActive: STATUS_VALUE.active,
                  }),
                })
              }
              asyncRequestHelper={(res) => res?.data?.items}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FilterForm
