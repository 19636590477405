import { combineReducers } from 'redux'

import {
  MASTER_GET_LIST_CHECK_IN_EMPLOYEE,
  MASTER_GET_LIST_CHECK_IN_EMPLOYEE_FAILED,
  MASTER_GET_LIST_CHECK_IN_EMPLOYEE_SUCCESS,
} from '../actions/report-check-in-employee'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listReportCheckInEmployee(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_CHECK_IN_EMPLOYEE:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_CHECK_IN_EMPLOYEE_SUCCESS:
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        total: data.meta.total,
        data: data.items || [],
      }
    case MASTER_GET_LIST_CHECK_IN_EMPLOYEE_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  listReportCheckInEmployee,
})
