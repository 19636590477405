import React, { useEffect, useMemo } from 'react'

import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
} from '~/utils'

import { EXPORT_TYPE } from '../../constants'
import { apiExport, apiGetTemplate } from '../../redux/apis/import-export'
import useVehicleRegister from '../../redux/hooks/useVehicleRegister'
import { ROUTE } from '../../routes/config'
import { TAB_VALUE, tabList } from './const'
import FilterForm from './filter-form'

const VehicleRegister = () => {
  const history = useHistory()
  const { t } = useTranslation(['buseye'])
  const { canAccess } = useApp()

  const DEFAULT_FILTERS = {}
  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setTab,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listVehicleRegister: {
        isLoading,
        data,
        total,
        totalCanceled,
        totalRegistered,
      },
    },
    actions: { actGetListVehicleRegister },
  } = useVehicleRegister()
  const breadcrumbs = [
    {
      route: ROUTE.REGISTER_VEHICLE.LIST.PATH,
      title: ROUTE.REGISTER_VEHICLE.LIST.TITLE,
    },
  ]
  const columns = useMemo(() => [
    {
      field: 'emp_code',
      headerName: t('vehicleRegister.employeeCode'),
      width: 60,
      sortable: true,
    },
    {
      field: 'name_emp',
      headerName: t('vehicleRegister.employeeName'),
      width: 100,
      sortable: true,
    },
    {
      field: 'sectionName',
      headerName: t('vehicleRegister.section'),
      width: 60,
      sortable: true,
    },
    {
      field: 'unitName',
      headerName: t('vehicleRegister.unit'),
      width: 100,
      sortable: true,
    },
    {
      field: 'codeRoute',
      headerName: t('vehicleRegister.routeCode'),
      width: 80,
    },
    {
      field: 'nameRoute',
      headerName: t('vehicleRegister.routeName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'ppCode',
      headerName: t('vehicleRegister.pointCode'),
      width: 80,
    },
    {
      field: 'ppName',
      headerName: t('vehicleRegister.pointName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'nameShift',
      headerName: t('vehicleRegister.shift'),
      width: 80,
    },
    {
      field: 'datetime',
      headerName: t('vehicleRegister.dateGoing'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { datetime } = params?.row
        return convertUtcDateTimeToLocalTz(datetime)
      },
    },
    {
      field: 'status',
      headerName: t('vehicleRegister.status'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { status } = params?.row
        return convertStatus(status)
      },
    },
  ])

  const convertStatus = (status) => {
    switch (status) {
      case 'REGISTERED':
        return (
          <Typography color={'#4caf50'}>
            {' '}
            {t('vehicleRegister.registed')}
          </Typography>
        )
      case 'CANCELED':
        return (
          <Typography color={'#f44336'}>
            {' '}
            {t('vehicleRegister.cancel')}
          </Typography>
        )
      default:
        return ''
    }
  }
  const getStatus = (tab) => {
    switch (tab) {
      case 0:
        return 'all'
      case 1:
        return 'Registered'
      case 2:
        return 'Canceled'
      default:
        return 'all'
    }
  }

  const refreshData = () => {
    const { ...dataFilter } = filters
    const filterParam = {
      ...dataFilter,
      route: filters.route?.code,
      codeShift: filters.codeShift?.code,
      status: getStatus(tab),
    }
    if (!tab) delete filterParam.status
    if (filterParam.updatedAt?.some((el) => el === null))
      delete filterParam.updatedAt
    const params = {
      page,
      limit: pageSize,
      filter: convertFilterParams(filterParam, columns),
      type: tab || 0,
      sort: convertSortParams(sort),
    }
    actGetListVehicleRegister(params)
  }

  const tableConfig = useMemo(() => {
    switch (tab) {
      case TAB_VALUE.cancel:
        return {
          columns: columns,
          total: totalCanceled,
        }
      case TAB_VALUE.registed:
        return {
          columns: columns,
          total: totalRegistered,
        }
      default:
        return {
          columns: columns,
          total: total,
        }
    }
  }, [tab, t, totalRegistered, totalCanceled, total])
  useEffect(() => {
    actGetListVehicleRegister()
  }, [])

  let actionHeader = [
    <ImportExport
      name={t('importExportMenu.export')}
      onExport={
        canAccess(FUNCTION_CODE.USER_EXPORT_BUSSINESS_ROSTER_CALENDAR)
          ? () => {
              const { ...dataFilter } = filters
              const params = {
                filter: convertFilterParams(
                  {
                    ...dataFilter,
                    codeRoutes: filters.codeRoutes?.code,
                    codeShift: filters.codeShift?.code,
                  },
                  columns,
                ),
                sort: convertSortParams(sort),
                type: EXPORT_TYPE.VEHICLE_REGISTER,
              }
              return apiExport(params)
            }
          : null
      }
      onDownloadTemplate={() => apiGetTemplate(EXPORT_TYPE.VEHICLE_REGISTER)}
      onRefresh={refreshData}
    />,
  ]

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        registed: totalRegistered,
        cancel: totalCanceled,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.vehicleRegister')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <TaskBar left={actionHeader} />
      <FilterArea
        tab={tab}
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: <FilterForm />,
          defaultValues: DEFAULT_FILTERS,
        }}
      />
      <div>
        <DataTable
          rows={data || []}
          pageSize={pageSize}
          page={page}
          columns={tableConfig.columns}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortChange={setSort}
          // onSelectionChange={setSelectedRows}
          // selected={selectedRows}
          total={tableConfig.total}
          title={t('master.title')}
          sort={sort}
          tabs={tabElement}
        />
      </div>
    </Page>
  )
}

export default VehicleRegister
