import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import Icon from '~/components/Icon'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import {
  APPROVED_OPTIONS,
  APPROVED_VALUE,
  EXPORT_REPORT_TYPE,
  SEND_MAIL_TYPE,
  TYPE_OT_AND_BT,
  DEFAULT_FILTERS_DATE_FROM_TO_OF_MONTH
} from '~/modules/master/constants'
import { apiExportReport } from '~/modules/master/redux/apis/import-export'
import useAttendance from '~/modules/master/redux/hooks/useAttendance'
import useReportOt from '~/modules/master/redux/hooks/useReportOt'
import { ROUTE } from '~/modules/master/routes/config'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
} from '~/utils'

import PopupEmail from '../../attendance/popup-email'
import { tabList } from '../constants'
import PopupSync from '../popup-sync'
import RegisterRoute from '../register-vehicle'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    title: ROUTE.REPORT.TITLE,
  },
  {
    route: ROUTE.REPORT.REPORT_OT.LIST.PATH,
    title: ROUTE.REPORT.REPORT_OT.LIST.TITLE,
  },
]

const DEFAULT_FILTERS = {
  rangeDate: DEFAULT_FILTERS_DATE_FROM_TO_OF_MONTH
}
const ReportOt = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { canAccess } = useApp()

  const [selectedRows, setSelectedRows] = useState([])
  const [isSync, setIsSync] = useState(false)
  const [syncQuery, setSyncQuery] = useState(null)
  const [sendEmail, setSendEmail] = useState(null)
  const [registerVehicle, setRegisterVehicle] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setTab,
  } = useQueryState({ filters: DEFAULT_FILTERS })

  const {
    data: {
      listReportOt: { data, total = 0, isLoading },
      syncReportOtBu: { isLoading: syncReportOtBuLoading },
    },
    actions: { actGetReportOt, actSyncReportOtBu },
  } = useReportOt()

  const {
    data: {
      detailRegisterVehicleOt: { data: detailRegisterVehicleOt },
      detailRegisterVehicleBuTravel: { data: detailRegisterVehicleBuTravel },
      registerVehicleOt: { isLoading: registerVehicleOtLoading },
      registerVehicleBuTravel: { isLoading: registerVehicleBuTravelLoading },
    },
    actions: {
      actRegisterVehicleOt,
      actRegisterVehicleBuTravel,
      actDetailRegisterVehicleOt,
      actDetailRegisterVehicleOtReset,
      actDetailRegisterVehicleBuTravel,
      actDetailRegisterVehicleBuTravelReset,
    },
  } = useAttendance()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [tab])

  const columns = useMemo(() => [
    {
      field: 'empCode',
      headerName: t('reportOt.codeEmp'),
      width: 150,
      visible: 'always',
      sortable: true,
    },
    {
      field: 'empName',
      headerName: t('reportOt.nameEmp'),
      width: 150,
      sortable: true,
    },
    {
      field: 'orgName',
      headerName: t('reportOt.orgStructure'),
      width: 150,
      sortable: true,
    },
    {
      field: 'orgCode',
      headerName: t('reportOt.orgCode'),
      width: 150,
      sortable: true,
    },
    {
      field: 'type',
      headerName: t('reportOt.purpose'),
      width: 150,
      sortable: true,
      // renderCell: (params) => {
      //   const { type } = params?.row
      //   return t(TYPE_OT_AND_BT[type])
      // },
    },

    {
      field: 'timeFrom',
      headerName: t('reportOt.dateFrom'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { timeFrom } = params?.row
        return convertUtcDateTimeToLocalTz(timeFrom)
      },
    },
    {
      field: 'timeTo',
      headerName: t('reportOt.dateTo'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { timeTo } = params?.row
        return convertUtcDateTimeToLocalTz(timeTo)
      },
    },
    {
      field: 'placeFrom',
      headerName: t('reportOt.placeFrom'),
      width: 150,
    },
    {
      field: 'placeTo',
      headerName: t('reportOt.placeTo'),
      width: 150,
    },
    {
      field: 'note',
      headerName: t('reportOt.note'),
      width: 150,
    },
    {
      field: 'status',
      headerName: t('reportOt.status'),
      width: 150,
      renderCell: (params) => {
        const { approved } = params.row
        return (
          <Status
            options={APPROVED_OPTIONS}
            value={!!approved ? APPROVED_VALUE.approved : APPROVED_VALUE.reject}
            variant="text"
          />
        )
      },
    },
    {
      field: 'lgaVehicle',
      headerName: t('reportOt.lgaVehicle'),
      width: 150,
      renderCell: (params) => {
        const { descriptionRegisterVehicle } = params?.row
        return descriptionRegisterVehicle
      },
    },
    {
      field: 'taxiCode',
      headerName: t('reportOt.taxiCode'),
      width: 150,
      renderCell: (params) => {
        const { ticket = [] } = params?.row
        return ticket?.join(', ')
      },
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 100,
      align: 'center',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Guard
              code={
                FUNCTION_CODE.USER_DETAIL_REGISTER_VEHICLE_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL
              }
            >
              <IconButton
                icon="bus"
                onClick={() => openRegister(params.row)}
                tooltipText={t('common.registerRoute')}
              />
            </Guard>
            <Guard
              code={
                FUNCTION_CODE.USER_SEND_MAIL_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL
              }
            >
              <IconButton
                icon="sendEmail"
                onClick={() => openSendEmail([params.row])}
                tooltipText={t('common.email')}
              />
            </Guard>
          </Box>
        )
      },
    },
  ])

  const refreshData = () => {
    const { orgStructure, ...paramsFilter } = filters
    const params = {
      page,
      limit: pageSize,
      filter: convertFilterParams(
        {
          ...paramsFilter,
          orgStructureIds: orgStructure?.map((item) => item.id),
        },
        [...columns, { field: 'rangeDate', filterFormat: 'date' }],
      ),
      sort: convertSortParams(sort),
    }
    actGetReportOt(params)
    handleCloseRegister()
    closeConfirmSync()
    closeSyncPopup()
    // closeSendEmail()
    // actions.searchProducingSteps(params)
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
        working: 1,
        unregistered: 22,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  const openSyncPopup = () => {
    setIsSync(true)
  }

  const closeSyncPopup = () => {
    setIsSync(false)
  }

  const openConfirmSync = (values) => {
    setSyncQuery(values)
  }

  const closeConfirmSync = () => {
    setSyncQuery(null)
  }

  const openSendEmail = (val) => {
    setSendEmail(val)
  }

  const closeSendEmail = () => {
    setSendEmail(null)
    refreshData()
  }

  const openRegister = (val) => {
    setRegisterVehicle(val)

    if (val.type === TYPE_OT_AND_BT.Overtime) {
      actDetailRegisterVehicleOt(val.id)
    }
    if (val.type === TYPE_OT_AND_BT.BussinessTravel) {
      actDetailRegisterVehicleBuTravel(val.id)
    }
  }

  const handleSubmitRegister = (values) => {
    const body = {
      descriptionRegisterVehicle: values.descriptionRegisterVehicle,
      jobDetails: values.jobDetails?.map((item) => ({
        id: item.id,
        code: item.code,
        vehicleType: item.vehicleType,
        createdBy: item.createdBy?.id,
      })),
      type: SEND_MAIL_TYPE.REPORT,
    }
    if (registerVehicle?.type === TYPE_OT_AND_BT.Overtime) {
      actRegisterVehicleOt({ id: values.id, body }, refreshData)
    } else {
      actRegisterVehicleBuTravel({ id: values.id, body }, refreshData)
    }
  }

  const handleCloseRegister = () => {
    setRegisterVehicle(null)
    actDetailRegisterVehicleOtReset()
    actDetailRegisterVehicleBuTravelReset()
  }

  const syncButton = (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={openSyncPopup}
      >
        <Icon
          name="assign"
          fill="#FF9054"
          stroke="#FF9054"
          sx={{ marginRight: '5px' }}
        />
        {t('common.sync')}
      </Box>
      <Box sx={{ margin: '0 5px' }}>|</Box>
    </>
  )

  const exportButton = (
    <Guard
      code={FUNCTION_CODE.USER_EXPORT_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL}
    >
      <ImportExport
        name={t('reportOt.fileNameExport')}
        onExport={() => {
          const { orgStructure, ...paramsFilter } = filters
          const params = {
            filter: convertFilterParams(
              {
                ...paramsFilter,
                orgStructureIds: orgStructure?.map((item) => item.id),
              },
              [...columns, { field: 'rangeDate', filterFormat: 'date' }],
            ),
            type: EXPORT_REPORT_TYPE.OVERTIME_PLAN_AND_BUSSINESS_TRAVEL,
          }

          return apiExportReport(params)
        }}
      />
    </Guard>
  )

  const beforeTopbar = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {!isEmpty(selectedRows) && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onClick={() => openSendEmail(selectedRows)}
          >
            <Icon
              name="sendEmail"
              // fill="#FF9054"
              // stroke="#FF9054"
              sx={{ marginRight: '5px' }}
            />
            {t('common.email')}
          </Box>
          <Box sx={{ margin: '0 5px' }}>|</Box>
        </>
      )}
      {canAccess(FUNCTION_CODE.USER_SYNCHRONIZE_BUSSINESS_TRAVEL) &&
      canAccess(FUNCTION_CODE.USER_SYNCHRONIZE_OVERTIME_PLAN)
        ? syncButton
        : null}

      {exportButton}
    </Box>
  )

  const handleSyncFailed = () => {
    closeConfirmSync()
  }

  const submitSyncData = () => {
    if (!syncQuery) return
    const query = {
      dateStart: syncQuery.dateStart.toISOString(),
      dateEnd: syncQuery.dateEnd.toISOString(),
    }
    actSyncReportOtBu(query, refreshData, handleSyncFailed)
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.reportOt')}
      loading={isLoading || syncReportOtBuLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <Guard
        code={FUNCTION_CODE.USER_SEARCH_REPORT_OVERTIME_AND_BUSSINESS_TRAVEL}
      >
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
            customWidth: {},
            gridSpace: 4,
          }}
        />
      </Guard>
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={total}
        title={t('master.title')}
        sort={sort}
        beforeTopbar={beforeTopbar}
        tabs={tabElement}
      />
      <PopupEmail
        t={t}
        open={!!sendEmail}
        data={sendEmail}
        screen={SEND_MAIL_TYPE.REPORT}
        onClose={closeSendEmail}
        // type={sendEmail?.type}
      />
      <PopupSync
        t={t}
        open={isSync}
        onSubmit={openConfirmSync}
        onClose={closeSyncPopup}
      />
      <PopupConfirm
        t={t}
        content={t('common.confirmSync')}
        open={!!syncQuery}
        onConfirm={submitSyncData}
        onCancel={closeConfirmSync}
      />
      <RegisterRoute
        t={t}
        registerVehicle={registerVehicle}
        open={!!registerVehicle}
        onClose={handleCloseRegister}
        onSubmit={handleSubmitRegister}
        registerData={
          registerVehicle?.type === TYPE_OT_AND_BT.BussinessTravel
            ? detailRegisterVehicleBuTravel
            : detailRegisterVehicleOt
        }
        tab={registerVehicle?.type}
        isLoading={registerVehicleOtLoading || registerVehicleBuTravelLoading}
      />
    </Page>
  )
}

export default ReportOt
