import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { apiGetListCatShiftList } from '~/modules/master/redux/apis/cat-shift'
import { apiGetListRouteList } from '~/modules/master/redux/apis/route'
import { convertFilterParams } from '~/utils'

import { CAT_SHIFT_LTF_CODE } from '../../report-all/constants'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item md={2}>
        <Field.DateRangePicker
          name="datetime"
          placeholder={t('vehicleRegister.dateGoing')}
        />
      </Grid>
      <Grid item xs={2}>
        <Field.Autocomplete
          name="codeShift"
          placeholder={t('vehicleRegister.shift')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListCatShiftList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
              filter: convertFilterParams({
                codes: CAT_SHIFT_LTF_CODE.join(','),
              }),
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        />
      </Grid>

      <Grid item xs={2}>
        <Field.TextField
          name="codeEmp"
          placeholder={t('vehicleRegister.employee')}
          inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <Icon name="search" />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={2}>
        <Field.Autocomplete
          name="route"
          placeholder={t('vehicleRegister.route')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListRouteList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        />
      </Grid>

      {/* <Grid item xs={2}>
        <Field.Autocomplete
          name="pickupPointCodes"
          placeholder={t('vehicleRegister.point')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListPickupPointList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
          multiple
        />
      </Grid> */}
    </>
  )
}

export default FilterForm
