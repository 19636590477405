import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListRoutePointRegister = (params) => {
  const uri = `/human-resource/profile-custom-registered-route`
  return api.get(uri, params)
}

export const apiPostApproveRoutePoint = (params) => {
  const uri = `/human-resource/profile-custom-registered-route/approve`
  const formData = params instanceof URLSearchParams 
  ? params 
  : new URLSearchParams(params)
  
return api.patch(uri, formData, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
}

export const apiPostApproveListRoutePoint = (params) => {
  const uri = `/human-resource/profile-custom-registered-route/approve-list`
  const formData = params instanceof URLSearchParams 
  ? params 
  : new URLSearchParams(params)
  
return api.patch(uri, formData, {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})
}

export const apiPostRejectRoutePoint = (params) => {
  const uri = `/human-resource/profile-custom-registered-route/reject`
  const formData = params instanceof URLSearchParams 
    ? params 
    : new URLSearchParams(params)
    
  return api.patch(uri, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export const apiPostRejectListRoutePoint = (params) => {
  const uri = `/human-resource/profile-custom-registered-route/reject-list`
  const formData = params instanceof URLSearchParams 
    ? params 
    : new URLSearchParams(params)
    
  return api.patch(uri, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}