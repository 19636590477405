import moment from 'moment'

import {
  ASYNC_SEARCH_LIMIT,
  MODAL_MODE,
  TEXTFIELD_ALLOW,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import IconButton from '~/components/IconButton'
import { STATUS_VALUE } from '~/modules/master/constants'
import { apiGetListCatShift } from '~/modules/master/redux/apis/cat-shift'
import { apiGetUnregisterPickupPoint } from '~/modules/master/redux/apis/pickup-point'
import {
  apiGetListVehicleCategories,
  apiGetListVehicleCategoriesList,
} from '~/modules/master/redux/apis/vehicle-categories'
import { convertFilterParams } from '~/utils'

export const columnPickupPointTable = ({
  t,
  arrayHelpers,
  tableData,
  name,
  mode,
}) => [
    {
      field: '#',
      headerName: 'STT',
      width: 40,
      renderCell: (_, idx) => {
        return idx + 1
      },
    },
    {
      field: 'code',
      headerName: t('route.pickupPoints.code'),
      width: 60,
      renderCell: (params) => {
        const { pickupPoint } = params.row
        return pickupPoint?.code
      },
    },
    {
      field: 'codeEmp',
      headerName: t('route.pickupPoints.pickupPoint'),
      width: 150,
      visible: 'always',
      required: true,
      renderCell: (params, idx) => {
        const { pickupPoint } = params?.row
        return mode === MODAL_MODE.DETAIL ? (
          pickupPoint?.name
        ) : (
          <Field.Autocomplete
            name={`${name}[${idx}].pickupPoint`}
            placeholder={t('route.pickupPoints.pickupPoint')}
            asyncRequest={(s) =>
              apiGetUnregisterPickupPoint({
                keyword: s,
                limit: ASYNC_SEARCH_LIMIT,
                filter: convertFilterParams({
                  isActive: STATUS_VALUE.active,
                }),
              })
            }
            asyncRequestHelper={(res) => res?.data?.items}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
          />
        )
      },
    },
    {
      field: 'location',
      headerName: t('route.pickupPoints.location'),
      width: 350,
      renderCell: (params) => {
        const { pickupPoint } = params.row
        return pickupPoint?.location
      },
    },
    {
      field: 'timeDepartureD',
      headerName: t('route.pickupPoints.timeDepartureD'),
      width: 40,
      renderCell: (params, idx) => {
        return mode === MODAL_MODE.DETAIL ? (
          params.row.timeDepartureD
        ) : (
          <Field.TextField
            name={`${name}[${idx}].timeDepartureD`}
            placeholder={t('route.pickupPoints.timeDepartureD')}
            type="string"
          />
        )
      },
    },
    {
      field: 'timeDeparture1',
      headerName: t('route.pickupPoints.timeDeparture1'),
      width: 40,
      renderCell: (params, idx) => {
        return mode === MODAL_MODE.DETAIL ? (
          params.row.timeDeparture1
        ) : (
          <Field.TextField
            name={`${name}[${idx}].timeDeparture1`}
            placeholder={t('route.pickupPoints.timeDeparture1')}
            type="string"
          />
        )
      }
    },
    {
      field: 'timeDeparture2',
      headerName: t('route.pickupPoints.timeDeparture2'),
      width: 40,
      renderCell: (params, idx) => {
        return mode === MODAL_MODE.DETAIL ? (
          params.row.timeDeparture2
        ) : (
          <Field.TextField
            name={`${name}[${idx}].timeDeparture2`}
            placeholder={t('route.pickupPoints.timeDeparture2')}
            type="string"
          />
        )
      }
    },
    {
      field: 'timeDeparture3',
      headerName: t('route.pickupPoints.timeDeparture3'),
      width: 40,
      renderCell: (params, idx) => {
        return mode === MODAL_MODE.DETAIL ? (
          params.row.timeDeparture3
        ) : (
          <Field.TextField
            name={`${name}[${idx}].timeDeparture3`}
            placeholder={t('route.pickupPoints.timeDeparture3')}
            type="string"
          />
        )
      }
    },
    {
      field: 'action',
      width: 50,
      align: 'center',
      hide: tableData?.length <= 1 || mode === MODAL_MODE.DETAIL,
      sticky: 'right',
      renderCell: (params, idx) => {
        return (
          <IconButton
            icon="remove"
            onClick={() => {
              arrayHelpers?.remove(idx)
            }}
            // disabled={row.level === 1}
            tooltipText={t('common.remove')}
          />
        )
      },
    },
  ]

export const columnVehicleTable = ({
  t,
  arrayHelpers,
  tableData,
  name,
  mode,
}) => [
    {
      field: '#',
      headerName: 'STT',
      width: 40,
      renderCell: (_, idx) => {
        return idx + 1
      },
    },
    {
      field: 'vehicleCategory',
      headerName: t('route.vehicleCategories.vehicleCategory'),
      width: 150,
      renderCell: (params, idx) => {
        const { vehicleCategory } = params?.row
        return mode === MODAL_MODE.DETAIL ? (
          vehicleCategory?.name
        ) : (
          <Field.Autocomplete
            name={`${name}[${idx}].vehicleCategory`}
            placeholder={t('route.vehicleCategories.vehicleCategory')}
            asyncRequest={(s) =>
              apiGetListVehicleCategories({
                keyword: s,
                limit: ASYNC_SEARCH_LIMIT,
              })
            }
            asyncRequestHelper={(res) => res?.data?.items}
            getOptionLabel={(option) => `${option.code}`}
            isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
          />
        )
      },
    },
    {
      field: 'totalSeat',
      headerName: t('route.vehicleCategories.totalSeat'),
      width: 150,
      renderCell: (params) => {
        const { vehicleCategory } = params.row
        return vehicleCategory?.totalSeat
      },
    },
    {
      field: 'description',
      headerName: t('route.vehicleCategories.description'),
      width: 350,
    },
    {
      field: 'action',
      width: 50,
      align: 'center',
      hide: tableData?.length <= 1 || mode === MODAL_MODE.DETAIL,
      sticky: 'right',
      renderCell: (params, idx) => {
        const { row } = params
        return (
          <IconButton
            icon="remove"
            onClick={() => {
              arrayHelpers?.remove(idx)
            }}
            disabled={row.level === 1}
            tooltipText={t('common.remove')}
          />
        )
      },
    },
  ]

export const columnCatShiftTable = ({
  t,
  arrayHelpers,
  tableData,
  name,
  mode,
  setFieldValue,
}) => [
    {
      field: '#',
      headerName: 'STT',
      width: 40,
      renderCell: (_, idx) => {
        return idx + 1
      },
    },
    {
      field: 'catShift',
      headerName: t('route.catShifts.catShift'),
      width: 150,
      required: true,
      renderCell: (params, idx) => {
        const { catShift } = params?.row
        return mode === MODAL_MODE.DETAIL ? (
          catShift?.name
        ) : (
          <Field.Autocomplete
            name={`${name}[${idx}].catShift`}
            placeholder={t('route.catShifts.catShift')}
            asyncRequest={(s) =>
              apiGetListCatShift({
                keyword: s,
                limit: ASYNC_SEARCH_LIMIT,
              })
            }
            onChange={(val) => {
              if (!val) {
                setFieldValue(`${name}[${idx}].fixedVehicle`, '')
              }
            }}
            asyncRequestHelper={(res) => res?.data?.items}
            getOptionLabel={(option) => option.code}
            isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
          />
        )
      },
    },
    {
      field: 'vehicleType',
      headerName: t('settingFee.vehicleType'),
      minWidth: 150,
      required: true,
      renderCell: (params, idx) => {
        const { vehicleType } = params?.row

        return mode === MODAL_MODE.DETAIL ? (
          vehicleType?.name
        ) : (
          <Field.Autocomplete
            name={`${name}[${idx}].vehicleType`}
            placeholder={t('settingFee.vehicleType')}
            asyncRequest={(s) =>
              apiGetListVehicleCategoriesList({
                keyword: s,
                limit: ASYNC_SEARCH_LIMIT,
                filter: convertFilterParams({
                  isActive: STATUS_VALUE.active,
                }),
              })
            }
            asyncRequestHelper={(res) => res?.data?.items}
            getOptionLabel={(option) => option.code}
            isOptionEqualToValue={(opt, val) => opt?.code === val?.code}
          />
        )
      },
    },
    {
      field: 'totalVehicle',
      headerName: t('route.catShifts.totalVehicle'),
      width: 150,
      required: true,
      renderCell: (params, idx) => {
        const { totalVehicle } = params?.row
        return mode === MODAL_MODE.DETAIL ? (
          totalVehicle
        ) : (
          <Field.TextField
            name={`${name}[${idx}].totalVehicle`}
            placeholder={t('route.catShifts.totalVehicle')}
            type="number"
            allow={TEXTFIELD_ALLOW.NUMERIC}
          />
        )
      },
    },
    {
      field: 'fixedVehicle',
      headerName: t('route.catShifts.fixedVehicle'),
      width: 150,
      // required: true,
      renderCell: (params) => {
        const { vehicleType, totalVehicle = 0 } = params?.row
        return (vehicleType?.totalSeat || 0) * totalVehicle
      },
    },
    {
      field: 'timeDeparture',
      headerName: t('route.catShifts.timeDeparture'),
      width: 150,
      renderCell: (params) => {
        const { catShift } = params.row
        return catShift?.inTime ? moment(catShift.inTime).format('HH:mm') : ''
      },
    },
    {
      field: 'timeArrival',
      headerName: t('route.catShifts.timeArrival'),
      width: 150,
      renderCell: (params) => {
        const { catShift } = params.row
        return catShift?.outTime ? moment(catShift.outTime).format('HH:mm') : ''
      },
    },
    {
      field: 'action',
      width: 50,
      align: 'center',
      hide: tableData?.length <= 1 || mode === MODAL_MODE.DETAIL,
      sticky: 'right',
      renderCell: (params, idx) => {
        const { row } = params
        return (
          <IconButton
            icon="remove"
            onClick={() => {
              arrayHelpers?.remove(idx)
            }}
            disabled={row.level === 1}
            tooltipText={t('common.remove')}
          />
        )
      },
    },
  ]
