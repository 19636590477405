import { all } from 'redux-saga/effects'

import watchGetList from './get-list'


export default function* sagas() {
  yield all([
    watchGetList(),
  ])
}
