import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetListAttendanceNotExcludingHolidaySuccess,
  actGetListAttendanceNotExcludingHolidayFailed,
  MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
} from '../../actions/attendance-not-excluding-holiday'
import { apiGetListAttendanceNotExcludingHoliday } from '../../apis/attendance-not-excluding-holiday'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetList(action) {
  try {
    const response = yield call(
      apiGetListAttendanceNotExcludingHoliday,
      action?.payload,
    )

    if (response?.statusCode === 200) {
      const data = {
        data: response.data,
      }

      yield put(actGetListAttendanceNotExcludingHolidaySuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetListAttendanceNotExcludingHolidayFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetList() {
  yield takeLatest(MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY, doGetList)
}
