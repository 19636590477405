import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actSyncAttendanceNotExcludingHolidayFailed,
  actSyncAttendanceNotExcludingHolidaySuccess,
  MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
} from '../../actions/attendance-not-excluding-holiday'
import { apiSyncAttendanceNotExcludingHoliday } from '../../apis/attendance-not-excluding-holiday'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doSync(action) {
  try {
    const response = yield call(
      apiSyncAttendanceNotExcludingHoliday,
      action?.payload,
    )
    if (response?.statusCode === 200) {
      const data = {
        data: response.data?.items,
        total: response.data?.meta?.total,
      }

      yield put(actSyncAttendanceNotExcludingHolidaySuccess(data))
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actSyncAttendanceNotExcludingHolidayFailed())
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchSync() {
  yield takeLatest(MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY, doSync)
}
