import React, { useEffect, useMemo, useState } from 'react'

import { Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import Tabs from '~/components/Tabs'
import useUserInfo from '~/modules/configuration/redux/hooks/useUserInfo'
import { EXPORT_REPORT_TYPE, DEFAULT_FILTERS_DATE_FROM_TO_OF_MONTH } from '~/modules/master/constants'
import { apiExportReport } from '~/modules/master/redux/apis/import-export'
import { apiGetListVehicleCategoriesList } from '~/modules/master/redux/apis/vehicle-categories'
import useReportAll from '~/modules/master/redux/hooks/useReportAll'
import { ROUTE } from '~/modules/master/routes/config'
import { useClasses } from '~/themes'
import palette from '~/themes/palette'
import { convertFilterParams, convertSortParams } from '~/utils'

import { enumerateDaysBetweenDates } from '../constants'
import UpdateVehicle from '../update-vehicle'
import FilterForm from './filter-form'
import { validationSchema } from './filter-form/schema'
import style from './style'

const breadcrumbs = [
  {
    title: ROUTE.REPORT.TITLE,
  },
  {
    route: ROUTE.REPORT.REPORT_ALL.LIST.PATH,
    title: ROUTE.REPORT.REPORT_ALL.LIST.TITLE,
  },
]

const DEFAULT_FILTERS = {
  rangeDate: DEFAULT_FILTERS_DATE_FROM_TO_OF_MONTH
}

const tooltipContent = ({ t, fullName, note }) => {
  return (
    <Box>
      <Box>
        {t('reportAll.updateBy')}: {fullName}
      </Box>
      <Box>
        {t('reportAll.note')}: {note}
      </Box>
    </Box>
  )
}

const ReportAll = () => {
  const { t } = useTranslation(['buseye'])
  const classes = useClasses(style)
  const { canAccess } = useApp()
  const history = useHistory()

  const [updateVehicle, setUpdateVehicle] = useState(null)
  const [vehicleCategories, setVehicleCategories] = useState([])

  const { page, pageSize, sort, filters, keyword, tab, setSort, setFilters } =
    useQueryState({
      filters: DEFAULT_FILTERS,
    })

  const {
    data: {
      listReportAll: { data, isLoading },
    },
    actions: { actGetReportAll },
  } = useReportAll()

  const {
    data: { userInfo },
  } = useUserInfo()

  const openUpdateVehicle = (val) => {
    setUpdateVehicle(val)
  }

  const closeUpdateVehicle = () => {
    setUpdateVehicle(null)
  }

  const genSubColumns = (subKeys) => {
    return subKeys.map((sub) => ({
      field: sub.key,
      headerName: t(sub.title),
      width: 60,
      align: 'center',
      visible: 'always',
      noCache: true,
      renderCell: (params) => {
        const { row } = params
        const isClick =
          !sub.key?.includes('_guest') &&
          !sub.key?.includes('total_') &&
          !['D', 'Other', 'All'].includes(row?.key)
        const updateData = row[sub.key + '_customize']
          ? row[sub.key + '_customize']
          : {}

        return (
          <Tooltip
            title={
              isClick && !isEmpty(updateData)
                ? tooltipContent({
                    t,
                    fullName: updateData?.fullName,
                    note: updateData?.note,
                  })
                : ''
            }
            arrow
            placement="top"
          >
            <Box
              sx={{
                color: row[sub.key + '_customize']
                  ? palette.status.inProgress.background
                  : '',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...(isClick &&
                canAccess(
                  FUNCTION_CODE.USER_MANEUVER_REINFORCEMENT_VEHICLES_REPORT_OVERALL,
                )
                  ? {
                      ':hover': {
                        background: '#E1E1E1',
                      },
                      cursor: 'pointer',
                    }
                  : {}),
              }}
              onDoubleClick={() => {
                if (
                  isClick &&
                  row &&
                  sub.key &&
                  canAccess(
                    FUNCTION_CODE.USER_MANEUVER_REINFORCEMENT_VEHICLES_REPORT_OVERALL,
                  )
                ) {
                  const [year, month, day, vehicleTypeCode] = sub.key.split('_')

                  openUpdateVehicle({
                    value: row[sub.key],
                    routeCode: row.routeCode,
                    shiftCode: row.shiftCode,
                    updatedBy: userInfo?.id,
                    vehicleTypeCode,
                    totalSeat: row[sub.key + '_totalSeat'],
                    workDate: `${year}-${month}-${day}`,
                    note: '',
                    fixedVehicle: row.fixedVehicle,
                    guest: row[`${year}_${month}_${day}_guest`],
                    ...updateData,
                  })
                }
              }}
            >
              {row[sub.key] || '-'}
            </Box>
          </Tooltip>
        )
      },
    }))
  }

  const genColumns = (dates = []) => {
    return dates.map((date) => {
      const column = {
        field: date.key,
        headerName: date.title,
        align: 'center',
        visible: 'always',
        noCache: true,
        columns: genSubColumns(date.subKeys),
      }
      column.width = column.columns.length * 60
      return column
    })
  }

  const getVehicleCateData = async () => {
    const res = await apiGetListVehicleCategoriesList({
      sort: convertSortParams({ orderBy: 'totalSeat', order: 'DESC' }),
    })
    if (res?.data?.items && !isEmpty(res?.data?.items)) {
      setVehicleCategories(res?.data?.items)
    }
  }

  const columns = useMemo(() => {
    if (isEmpty(vehicleCategories) || isEmpty(data)) return []

    const { rangeDate } = filters
    const dates = enumerateDaysBetweenDates(
      rangeDate[0],
      rangeDate[1],
      vehicleCategories,
    )

    const newColumns = [
      {
        field: 'routeCode',
        headerName: t('reportAll.route'),
        width: 150,
        renderCell: (params) => {
          const { row } = params
          return (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                justifyContent: row.key ? 'right' : 'center',
                alignItems: 'center',
                paddingRight: row.key ? '18px' : '',
                background: row.key ? '#DBEAFC' : '',
              }}
            >
              {row.routeCode}
            </Box>
          )
        },
      },
      {
        field: 'fixedVehicle',
        headerName: t('reportAll.fixedVehicle'),
        align: 'center',
        width: 150,
      },
      {
        field: 'shiftCode',
        headerName: t('reportAll.catShift'),
        align: 'center',
        width: 100,
      },
      ...genColumns(dates),
      ...genColumns([
        {
          key: 'total',
          title: t('reportAll.total'),
          subKeys: [
            { key: 'total_guest', title: 'reportAll.guest' },
            ...vehicleCategories.map((item) => {
              return {
                key: 'total_' + item.code,
                title: item.name,
              }
            }),
          ],
        },
      ]),
    ]

    return newColumns
  }, [filters, data, vehicleCategories, openUpdateVehicle, userInfo])

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, keyword, tab])

  useEffect(() => {
    getVehicleCateData()
  }, [])

  const refreshData = () => {
    const filterParam =         {
      ...filters,
      codeOrgStructure: filters.codeOrgStructure?.code,
      codeRoute: filters.codeRoute?.code,
      codeShift: filters.codeShift?.code,
      pickupPointCode: filters.pickupPointCode?.code,
    }
    delete filterParam.type
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(
        filterParam,
        [...columns, { field: 'rangeDate', filterFormat: 'date' }],
      ),
      type: filters.type,
      sort: convertSortParams(sort),
    }
    if(params.type === "0") delete params.type

    actGetReportAll(params)
    // actGetListVehicleCategories()
    setUpdateVehicle(null)
    // actions.searchProducingSteps(params)
  }

  const beforeTopbar = (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      onClick={() => {}}
    >
      <Guard code={FUNCTION_CODE.USER_EXPORT_REPORT_OVERALL}>
        <ImportExport
          name={t('reportAll.fileNameExport')}
          onExport={() => {
            const params = {
              filter: convertFilterParams(
                {
                  ...filters,
                  codeRoute: filters.codeRoute?.code,
                  codeShift: filters.codeShift?.code,
                  pickupPointCode: filters.pickupPointCode?.code,
                },
                [...columns, { field: 'rangeDate', filterFormat: 'date' }],
              ),
              type: EXPORT_REPORT_TYPE.OVERALL,
            }

            return apiExportReport(params)
          }}
        />
      </Guard>
    </Box>
  )

  const tabElement = (
    <Tabs
      list={[
        {
          label: t('reportAll.tableTitle'),
          value: '',
        },
      ]}
      color="#222222"
    />
  )

  const formatTableData = (tableData = []) => {
    return tableData?.map((item) => {
      switch (item.key) {
        case 'D':
          return {
            ...item,
            routeCode: t('reportAll.totalD'),
          }
        case 'Other':
          return {
            ...item,
            routeCode: t('reportAll.total3'),
          }
        case 'All':
          return {
            ...item,
            routeCode: t('reportAll.totalDay'),
          }

        default:
          return item
      }
    })
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.reportAll')}
      loading={isLoading}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <Guard code={FUNCTION_CODE.USER_SEARCH_REPORT_OVERALL}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
            validationSchema: validationSchema(t),
            customWidth: {},
          }}
        />
      </Guard>

      <div className={classes.tableContainer}>
        <DataTable
          rows={formatTableData(data)}
          columns={columns}
          onSortChange={setSort}
          sort={sort}
          hideFooter
          beforeTopbar={beforeTopbar}
          tabs={tabElement}
          mergeSetting={{
            rowSpanKey: ['key'],
            colSpan: {D: { routeCode: 3 }, Other: { routeCode: 3 }, All: { routeCode: 3 }},
            removeKeys: {D: ['fixedVehicle', 'shiftCode'], Other: ['fixedVehicle', 'shiftCode'], All: ['fixedVehicle', 'shiftCode']},
          }}
        />
      </div>
      <UpdateVehicle
        open={!!updateVehicle}
        data={updateVehicle}
        t={t}
        onClose={closeUpdateVehicle}
        refreshData={refreshData}
      />
    </Page>
  )
}

export default ReportAll
