import { api } from '~/services/api'

/**
 * @param { body: object} params Params will be sent to server
 * @returns {Promise}
 */
export const apiUpdateSettingAutoApproval = (body) => {
  const uri = `/system-config`
  return api.post(uri, body)
}

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetSettingAutoApproval = (params) => {
  const uri = `/system-config/get-list`
  return api.get(uri, params)
}
