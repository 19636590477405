// Action: Get list
export const MASTER_GET_LIST_CHECK_IN_EMPLOYEE =
  'MASTER_GET_LIST_CHECK_IN_EMPLOYEE'
export const MASTER_GET_LIST_CHECK_IN_EMPLOYEE_SUCCESS =
  'MASTER_GET_LIST_CHECK_IN_EMPLOYEE_SUCCESS'
export const MASTER_GET_LIST_CHECK_IN_EMPLOYEE_FAILED =
  'MASTER_GET_LIST_CHECK_IN_EMPLOYEE_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListCheckInEmployee(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_CHECK_IN_EMPLOYEE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListCheckInEmployeeSuccess(payload) {
  return {
    type: MASTER_GET_LIST_CHECK_IN_EMPLOYEE_SUCCESS,
    payload: payload,
  }
}

export function actGetListCheckInEmployeeFailed() {
  return {
    type: MASTER_GET_LIST_CHECK_IN_EMPLOYEE_FAILED,
  }
}



export default {
  actGetListCheckInEmployee,
  actGetListCheckInEmployeeSuccess,
  actGetListCheckInEmployeeFailed,
}
