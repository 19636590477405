import { combineReducers } from 'redux'

import {
  MASTER_GET_SETTING_AUTO_APPROVAL,
  MASTER_GET_SETTING_AUTO_APPROVAL_FAILED,
  MASTER_GET_SETTING_AUTO_APPROVAL_SUCCESS,
  MASTER_UPDATE_SETTING_AUTO_APPROVAL,
  MASTER_UPDATE_SETTING_AUTO_APPROVAL_FAILED,
  MASTER_UPDATE_SETTING_AUTO_APPROVAL_SUCCESS,
  } from '../actions/setting-auto-approval'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function updateSettingAutoApproval(state = { isLoading: false, data: null }, action) {
  switch (action.type) {
    case MASTER_UPDATE_SETTING_AUTO_APPROVAL:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_UPDATE_SETTING_AUTO_APPROVAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      }
    case MASTER_UPDATE_SETTING_AUTO_APPROVAL_FAILED:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return {
        ...state,
      }
  }
}

function getSettingAutoApproval(state = { isLoading: false, data: [] }, action) {
  switch (action.type) {
    case MASTER_GET_SETTING_AUTO_APPROVAL:
      return {
        ...state,
        isLoading: true,
      }
    case MASTER_GET_SETTING_AUTO_APPROVAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload?.data?.items,
      }
    case MASTER_GET_SETTING_AUTO_APPROVAL_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return {
        ...state,
      }
  }
}

export default combineReducers({
  updateSettingAutoApproval,
  getSettingAutoApproval,
})
