export const OPTION_TYPE = [
  {
    label: 'reportBusinessTravelNotCheckIn.filter.checkIn',
    value: 'Y',
  },
  {
    label: 'reportBusinessTravelNotCheckIn.filter.notCheckIn',
    value: 'N',
  },
  {
    label: 'reportBusinessTravelNotCheckIn.filter.all',
    value: 'ALL',
  },
]
