import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import act from '~/modules/master/redux/actions/report-check-in-employee'

const useReportCheckInEmployee = () => {
  const data = useSelector((state) => get(state, 'master.reportCheckInEmployee'))

  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(act, dispatch), [dispatch])
  return {
    actions,
    data,
  }
}

export default useReportCheckInEmployee
