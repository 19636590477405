import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetListRoutePointRegisterFailed,
  actGetListRoutePointRegisterSuccess,
  MASTER_GET_LIST_ROUTE_POINT_REGISTER,
} from '../../actions/route-point-register'
import { apiGetListRoutePointRegister } from '../../apis/route-point-register'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetList(action) {
  try {
    const response = yield call(apiGetListRoutePointRegister, action?.payload)

    if (response?.statusCode === 200) {
      const data = {
        data: response.data,
        meta: response.data.meta
      }
      yield put(actGetListRoutePointRegisterSuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetListRoutePointRegisterFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetList() {
  yield takeLatest(MASTER_GET_LIST_ROUTE_POINT_REGISTER, doGetList)
}
