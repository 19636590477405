import React, { useEffect, useMemo, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import { ASYNC_SEARCH_LIMIT } from '~/common/constants'
import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import {
  convertFilterParams,
  convertSortParams,
  convertUtcDateTimeToLocalTz,
} from '~/utils'

import { EXPORT_TYPE, DEFAULT_FILTERS_DATE_FROM_TO_OF_MONTH } from '../../constants'
import { apiExport, apiGetTemplate } from '../../redux/apis/import-export'
import { apiGetListPickupPointList } from '../../redux/apis/pickup-point'
import {
  apiPostApproveListRoutePoint,
  apiPostApproveRoutePoint,
  apiPostRejectListRoutePoint,
  apiPostRejectRoutePoint,
} from '../../redux/apis/route-point-register'
import useRoutePointRegister from '../../redux/hooks/useRoutePointRegister'
import { ROUTE } from '../../routes/config'
import { TAB_VALUE, tabList } from './const'
import FilterForm from './filter-form'
import ConfirmReject from './modal-confirm'

const DEFAULT_FILTERS = {
  updatedAt: DEFAULT_FILTERS_DATE_FROM_TO_OF_MONTH
}

const RoutePointRegister = () => {
  const [selectedRows, setSelectedRows] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [optionPickupPoint, setOptionPickupPoint] = useState([])
  const [openModalConfirm, setOpenModalConfirm] = useState(null)
  const [itemReject, setItemReject] = useState('')
  const [multiple, setMultiple] = useState('')
  const history = useHistory()
  const { canAccess } = useApp()
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const { t } = useTranslation(['buseye'])
  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    setTab,
    selectedRowsDeps,
  } = useQueryState({
    filters: DEFAULT_FILTERS,
  })

  const {
    data: {
      listRoutePointRegister: {
        isLoading,
        data,
        total,
        totalRejected,
        totalWaitingApproval,
        totalApproved,
      },
    },
    actions: { actGetListRoutePointRegister },
  } = useRoutePointRegister()
  const breadcrumbs = [
    {
      route: ROUTE.REGISTER_ROUTE_POINT.LIST.PATH,
      title: ROUTE.REGISTER_ROUTE_POINT.LIST.TITLE,
    },
  ]

  const handleApproval = async (data, multiple) => {
    try {
      if (!multiple) {
        if (
          data.status === 'CANCELED' ||
          data.status === 'REJECTED' ||
          data.status === 'APPROVED' ||
          !canAccess(FUNCTION_CODE.USER_APPROVE_PROFILE_CUSTOM_REGISTERED)
        )
          return
        const param = {
          id: data.id?.toString(),
        }
        const res = await apiPostApproveRoutePoint(param)
        if (res) {
          refreshData()
        }
      } else {
        if (!data.length) return
        const param = {
          ids: data.map((el) => el.id).join(','),
        }
        const res = await apiPostApproveListRoutePoint(param)
        if (res) {
          refreshData()
        }
      }
    } catch (error) {}
  }
  const handleReject = async (data, multiple, reason) => {
    try {
      if (!multiple) {
        if (
          data.status === 'CANCELED' ||
          data.status === 'REJECTED' ||
          data.status === 'APPROVED' ||
          !canAccess(FUNCTION_CODE.USER_REJECT_PROFILE_CUSTOM_REGISTERED)
        )
          return
        const param = {
          id: data.id,
          reason: reason,
        }
        const res = await apiPostRejectRoutePoint(param)
        if (res) {
          refreshData()
          setOpenModalConfirm(false)
        }
      } else {
        if (!data.length) return
        const param = {
          ids: data.map((el) => el.id).join(','),
          reason: reason,
        }
        const res = await apiPostRejectListRoutePoint(param)

        if (res) {
          refreshData()
          setOpenModalConfirm(false)
        }
      }
    } catch (error) {}
  }
  const columns = useMemo(() => [
    {
      field: 'updatedAt',
      headerName: t('RoutePointRegister.rangeDate'),
      width: 150,
      visible: 'always',
      sortable: true,
      renderCell: (params) => {
        const { updatedAt } = params?.row
        return convertUtcDateTimeToLocalTz(updatedAt)
      },
    },
    {
      field: 'empCode',
      headerName: t('RoutePointRegister.employeeCode'),
      width: 60,
      sortable: true,
    },
    {
      field: 'empName',
      headerName: t('RoutePointRegister.employeeName'),
      width: 100,
      sortable: true,
    },
    {
      field: 'sectionName',
      headerName: t('RoutePointRegister.section'),
      width: 60,
      sortable: true,
    },
    {
      field: 'unitName',
      headerName: t('RoutePointRegister.unit'),
      width: 100,
      sortable: true,
    },
    {
      field: 'routeCode',
      headerName: t('RoutePointRegister.routeCode'),
      width: 80,
    },
    {
      field: 'name_route',
      headerName: t('RoutePointRegister.routeName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'pickupPointCode',
      headerName: t('RoutePointRegister.pointCode'),
      width: 80,
    },
    {
      field: 'name_pickup_point',
      headerName: t('RoutePointRegister.pointName'),
      width: 150,
      sortable: true,
    },
    {
      field: 'status',
      headerName: t('RoutePointRegister.status'),
      width: 100,
      sortable: true,
      renderCell: (params) => {
        const { status } = params?.row
        return convertStatus(status)
      },
    },

    {
      field: 'reason',
      headerName: t('RoutePointRegister.reason'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { reason, status } = params?.row
        return status === 'REJECTED' && reason
      },
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 150,
      renderCell: (params) => {
        const { status } = params.row
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton
              icon="tick"
              onClick={() => handleApproval(params.row)}
              tooltipText={t('RoutePointRegister.approvel')}
              fill={
                (status === 'REJECTED' ||
                  status === 'CANCELED' ||
                  status === 'APPROVED' ||
                  !canAccess(
                    FUNCTION_CODE.USER_APPROVE_PROFILE_CUSTOM_REGISTERED,
                  )) &&
                '#D3D3D3'
              }
            />
            <IconButton
              icon="remove"
              onClick={() => {
                if (
                  status === 'REJECTED' ||
                  status === 'CANCELED' ||
                  status === 'APPROVED' ||
                  !canAccess(
                    FUNCTION_CODE.USER_REJECT_PROFILE_CUSTOM_REGISTERED,
                  )
                )
                  return
                setItemReject(params.row)
                setOpenModalConfirm(true)
              }}
              tooltipText={t('RoutePointRegister.reject')}
              fill={
                (status === 'REJECTED' ||
                  status === 'CANCELED' ||
                  status === 'APPROVED' ||
                  !canAccess(
                    FUNCTION_CODE.USER_REJECT_PROFILE_CUSTOM_REGISTERED,
                  )) &&
                '#D3D3D3'
              }
            />
          </Box>
        )
      },
    },
  ])

  const getOptionPickupPoint = async (route) => {
    try {
      const res = await apiGetListPickupPointList({
        keyword: route,
        limit: ASYNC_SEARCH_LIMIT,
      })
      if (res?.data?.items) {
        const option = res.data.items.map((item) => ({
          label: item.name,
          value: item.code,
        }))
        setOptionPickupPoint(option)
      }
    } catch (error) {}
  }
  useEffect(() => {
    getOptionPickupPoint()
  }, [])
  const convertStatus = (status) => {
    switch (status) {
      case 'WAITING':
        return (
          <Typography color={'#ff9800'}>
            {' '}
            {t('RoutePointRegister.waitingApproval')}
          </Typography>
        )
      case 'APPROVED':
        return (
          <Typography color={'#1976d2'}>
            {' '}
            {t('RoutePointRegister.approved')}
          </Typography>
        )
      case 'REGISTERED':
        return (
          <Typography color={'#4caf50'}>
            {' '}
            {t('RoutePointRegister.registed')}
          </Typography>
        )
      case 'CANCELED':
        return (
          <Typography color={'#f44336'}>
            {' '}
            {t('RoutePointRegister.cancel')}
          </Typography>
        )
      case 'REJECTED':
        return (
          <Typography color={'#f44336'}>
            {' '}
            {t('RoutePointRegister.reject')}
          </Typography>
        )
      default:
        return ''
    }
  }
  const getStatus = (tab) => {
    switch (tab) {
      case 0:
        return 'all'
      case 1:
        return 'WAITING'
      case 2:
        return 'APPROVED'
      case 3:
        return 'REJECTED'
      default:
        return 'all'
    }
  }
  const refreshData = () => {
    const { empCode, ...dataFilter } = filters
    const filterParam = {
      ...dataFilter,
      routeCode: filters.routeCode?.code,
      pickupPointCode: filters.pickupPointCode,
      status: getStatus(tab),
    }
    if (!tab) delete filterParam.status
    if (filterParam.updatedAt?.some((el) => el === null))
      delete filterParam.updatedAt
    const params = {
      page,
      limit: pageSize,
      keyword: empCode,
      filter: convertFilterParams(filterParam, columns),
      // type: tab || 0,
      sort: convertSortParams(sort),
    }
    actGetListRoutePointRegister(params)
    setSelectedRows([])
  }
  const tableConfig = useMemo(() => {
    switch (tab) {
      case TAB_VALUE.waitingApproval:
        return {
          columns: columns,
          total: totalWaitingApproval,
        }
      case TAB_VALUE.approved:
        return {
          columns: columns,
          total: totalApproved,
        }
      case TAB_VALUE.reject:
        return {
          columns: columns,
          total: totalRejected,
        }
      default:
        return {
          columns: columns,
          total: total,
        }
    }
  }, [tab, t, total, totalApproved, totalWaitingApproval, totalRejected])

  useEffect(() => {
    actGetListRoutePointRegister()
  }, [])

  useEffect(() => {
    const newData = data.map((el) => ({
      ...el,
    }))
    setDataTable(newData)
  }, [data])

  const isDisabled = useMemo(() => {
    const listDataSelect = data.filter((item) =>
      selectedRows.map((el) => el.id).includes(item.id),
    )
    const hasApprovedRows = listDataSelect.some(
      (item) =>
        item.status === 'APPROVED' ||
        item.status === 'REJECTED' ||
        item.status === 'CANCELED',
    )

    return hasApprovedRows
  }, [selectedRows, data])

  const getExport = async (params) => {
    try {
      setIsLoadingExport(true)
      const res = await apiExport(params)
      return res
    } finally {
      setIsLoadingExport(false)
    }
  }

  let actionHeader = [
    canAccess(FUNCTION_CODE.USER_APPROVE_LIST_PROFILE_CUSTOM_REGISTERED) ||
    canAccess(FUNCTION_CODE.USER_REJECT_LIST_PROFILE_CUSTOM_REGISTERED) ? (
      <Box display="flex" alignItems="center" mx={2}>
        {canAccess(
          FUNCTION_CODE.USER_APPROVE_LIST_PROFILE_CUSTOM_REGISTERED,
        ) && (
          <Box display="flex" alignItems="center">
            <IconButton
              icon="tick"
              onClick={() => {
                if (isDisabled) return
                handleApproval(selectedRows, 'Multiple')
              }}
              tooltipText={t('RoutePointRegister.approvel')}
              fill={(selectedRows.length < 1 || isDisabled) && '#D3D3D3'}
            />
            <Typography>{t('notification.action.confirm')}</Typography>
          </Box>
        )}
        {canAccess(
          FUNCTION_CODE.USER_REJECT_LIST_PROFILE_CUSTOM_REGISTERED,
        ) && (
          <Box display="flex" alignItems="center" ml={2}>
            <IconButton
              icon="remove"
              onClick={() => {
                setMultiple('Multiple')
                setOpenModalConfirm(true)
              }}
              tooltipText={t('RoutePointRegister.reject')}
              fill={(selectedRows.length < 1 || isDisabled) && '#D3D3D3'}
            />
            <Typography>{t('notification.action.reject')}</Typography>
          </Box>
        )}
      </Box>
    ) : null,
    <ImportExport
      name={t('importExportMenu.export')}
      onExport={
        canAccess(FUNCTION_CODE.USER_EXPORT_PROFILE_CUSTOME_REGISTERED)
          ? () => {
              const { ...dataFilter } = filters
              const filterParam = {
                ...dataFilter,
                routeCode: filters.routeCode?.code,
                pickupPointCode: filters.pickupPointCode?.code,
                status: getStatus(tab),
              }
              if (!tab) delete filterParam.status
              if (filterParam.updatedAt?.some((el) => el === null))
                delete filterParam.updatedAt

              const params = {
                filter: convertFilterParams(filterParam, columns),
                sort: convertSortParams(sort),
                type: EXPORT_TYPE.ROUTE_POINT_REGISTER,
              }
              if (!isEmpty(selectedRows)) {
                params.ids = JSON.stringify(
                  selectedRows?.map((x) => ({ id: x?.id })),
                )
              }
              return getExport(params)
            }
          : null
      }
      onDownloadTemplate={() =>
        apiGetTemplate(EXPORT_TYPE.ROUTE_POINT_REGISTER)
      }
      onRefresh={refreshData}
    />,
  ]

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total || 0,
        reject: totalRejected || 0,
        waitingApproval: totalWaitingApproval || 0,
        approved: totalApproved || 0,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])
  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])
  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.routePointRegister')}
      loading={isLoading || isLoadingExport}
      onBack={() => history.goBack()}
    >
      <TaskBar left={actionHeader} />
      <FilterArea
        tab={tab}
        values={filters}
        onApply={setFilters}
        quickFilters={{
          form: (
            <FilterForm
              getOptionPickupPoint={getOptionPickupPoint}
              optionPickupPoint={optionPickupPoint}
            />
          ),
          defaultValues: DEFAULT_FILTERS,
        }}
      />
      <div>
        <DataTable
          rows={dataTable || []}
          pageSize={pageSize}
          page={page}
          columns={tableConfig.columns}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onSortChange={setSort}
          onSelectionChange={setSelectedRows}
          selected={selectedRows}
          total={tableConfig.total}
          title={t('master.title')}
          sort={sort}
          tabs={tabElement}
        />
      </div>
      <ConfirmReject
        open={!!openModalConfirm}
        listData={selectedRows}
        data={itemReject}
        multiple={multiple}
        t={t}
        onClose={() => setOpenModalConfirm(false)}
        handleReject={handleReject}
      />
    </Page>
  )
}

export default RoutePointRegister
