import { combineReducers } from 'redux'

import {
  MASTER_GET_LIST_BUS_NOT_CHECK_IN,
  MASTER_GET_LIST_BUS_NOT_CHECK_IN_FAILED,
  MASTER_GET_LIST_BUS_NOT_CHECK_IN_SUCCESS,
} from '../actions/report-bus-not-check-in'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listReportBusNotCheckIn(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_BUS_NOT_CHECK_IN:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_BUS_NOT_CHECK_IN_SUCCESS:
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        total: data.meta.totalRosterCalendar,
        data: data.items || [],
      }
    case MASTER_GET_LIST_BUS_NOT_CHECK_IN_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  listReportBusNotCheckIn,
})
