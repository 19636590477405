import { combineReducers } from 'redux'

import {
  MASTER_GET_LIST_ROUTE_POINT_REGISTER,
  MASTER_GET_LIST_ROUTE_POINT_REGISTER_FAILED,
  MASTER_GET_LIST_ROUTE_POINT_REGISTER_SUCCESS
} from '../actions/route-point-register'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listRoutePointRegister(
  state = {
    isLoading: false,
    data: [],
    total: 0,
    totalRejected: 0,
    totalWaitingApproval: 0,
    totalApproved: 0,
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_ROUTE_POINT_REGISTER:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_ROUTE_POINT_REGISTER_SUCCESS:
      const { data, meta } = action.payload
      return {
        ...state,
        isLoading: false,
        data: data.list,
        total: meta?.total || 0,
        totalRejected: meta?.countRejected || 0,
        totalWaitingApproval: meta?.countWaiting || 0,
        totalApproved: meta?.countRegistered || 0,
        page: meta?.page || 1,
      }
    case MASTER_GET_LIST_ROUTE_POINT_REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}


export default combineReducers({
  listRoutePointRegister,
})
