import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  actGetListBusinessTravelNotCheckInSuccess,
  actGetListBusinessTravelNotCheckInFailed,
  MASTER_GET_LIST_BUSINESS_TRAVEL_NOT_CHECK_IN,
} from '../../actions/report-business-travel-not-check-in'
import { apiGetListBusinessTravelNotCheckIn } from '../../apis/report-business-travel-not-check-in'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doGetList(action) {
  try {
    const response = yield call(apiGetListBusinessTravelNotCheckIn, action?.payload)

    if (response?.statusCode === 200) {
      const data = {
        data: response.data,
      }

      yield put(actGetListBusinessTravelNotCheckInSuccess(data))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetListBusinessTravelNotCheckInFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchGetList() {
  yield takeLatest(MASTER_GET_LIST_BUSINESS_TRAVEL_NOT_CHECK_IN, doGetList)
}
