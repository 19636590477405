import { all, call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  MASTER_GET_SETTING_AUTO_APPROVAL,
  MASTER_UPDATE_SETTING_AUTO_APPROVAL,
  actGetSettingAutoApprovalFailed,
  actGetSettingAutoApprovalSuccess,
  actUpdateSettingAutoApprovalFailed,
  actUpdateSettingAutoApprovalSuccess,
} from '../../actions/setting-auto-approval'
import { apiUpdateSettingAutoApproval, apiGetSettingAutoApproval } from '../../apis/setting-auto-approval'

/**
 * Handle get data request and response
 * @param {object} action
 */

function* doUpdateSetting(action) {
  try {
    const response = yield call(apiUpdateSettingAutoApproval, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actUpdateSettingAutoApprovalSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      addNotification(response?.message, NOTIFICATION_TYPE.SUCCESS)
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actUpdateSettingAutoApprovalFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

function* doGetSetting(action) {
  try {
    const response = yield call(apiGetSettingAutoApproval, action?.payload)

    if (response?.statusCode === 200) {
      yield put(actGetSettingAutoApprovalSuccess({ data: response.data }))
      // Call callback action if provided
      if (action.onSuccess) {
        yield action.onSuccess()
      }
    } else {
      addNotification(response?.message, NOTIFICATION_TYPE.ERROR)
      throw new Error(response?.message)
    }
  } catch (error) {
    yield put(actGetSettingAutoApprovalFailed())
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchSettingAutoApproval() {
  yield all([
    takeLatest(MASTER_GET_SETTING_AUTO_APPROVAL, doGetSetting),
    takeLatest(MASTER_UPDATE_SETTING_AUTO_APPROVAL, doUpdateSetting),
  ])
}
