// Action: Get data
export const MASTER_GET_SETTING_AUTO_APPROVAL = 'MASTER_GET_SETTING_AUTO_APPROVAL'
export const MASTER_GET_SETTING_AUTO_APPROVAL_SUCCESS = 'MASTER_GET_SETTING_AUTO_APPROVAL_SUCCESS'
export const MASTER_GET_SETTING_AUTO_APPROVAL_FAILED = 'MASTER_GET_SETTING_AUTO_APPROVAL_FAILED'

// Action: update
export const MASTER_UPDATE_SETTING_AUTO_APPROVAL = 'MASTER_UPDATE_SETTING_AUTO_APPROVAL'
export const MASTER_UPDATE_SETTING_AUTO_APPROVAL_SUCCESS = 'MASTER_UPDATE_SETTING_AUTO_APPROVAL_SUCCESS'
export const MASTER_UPDATE_SETTING_AUTO_APPROVAL_FAILED = 'MASTER_UPDATE_SETTING_AUTO_APPROVAL_FAILED'

/** get data
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetSettingAutoApproval(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_SETTING_AUTO_APPROVAL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetSettingAutoApprovalSuccess(payload) {
  return {
    type: MASTER_GET_SETTING_AUTO_APPROVAL_SUCCESS,
    payload: payload,
  }
}

export function actGetSettingAutoApprovalFailed() {
  return {
    type: MASTER_GET_SETTING_AUTO_APPROVAL_FAILED,
  }
}


/** update
 * @param {
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateSettingAutoApproval(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_SETTING_AUTO_APPROVAL,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateSettingAutoApprovalSuccess(payload) {
  return {
    type: MASTER_UPDATE_SETTING_AUTO_APPROVAL_SUCCESS,
    payload: payload,
  }
}

export function actUpdateSettingAutoApprovalFailed() {
  return {
    type: MASTER_UPDATE_SETTING_AUTO_APPROVAL_FAILED,
  }
}

export default {
  actGetSettingAutoApproval,
  actGetSettingAutoApprovalSuccess,
  actGetSettingAutoApprovalFailed,
  actUpdateSettingAutoApproval,
  actUpdateSettingAutoApprovalSuccess,
  actUpdateSettingAutoApprovalFailed,
}
