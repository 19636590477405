import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListVehicleRegister = (params) => {
  const uri = `/bussiness-roster-calendar/get-list-bussiness-trip`
  return api.get(uri, params)
}

