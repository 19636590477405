import React, { useEffect, useMemo } from 'react'

import { Divider } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import Button from '~/components/Button'
import Page from '~/components/Page'
import { ROUTE } from '~/modules/master/routes/config'

import useSettingAutoApproval from '../../redux/hooks/useSettingAutoApproval'
import SettingForm from './form'

const breadcrumbs = [
  {
    title: ROUTE.SETTING.TITLE,
  },
  {
    route: ROUTE.SETTING.AUTO_APPROVAL.PATH,
    title: ROUTE.SETTING.AUTO_APPROVAL.TITLE,
  },
]
const SettingAutoApproval = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()

  const {
    data: {
      getSettingAutoApproval: { data = [], isLoading },
      updateSettingAutoApproval: { isLoading: isLoadingUpdate },
    },
    actions: { actGetSettingAutoApproval, actUpdateSettingAutoApproval },
  } = useSettingAutoApproval()

  const initialValues = useMemo(() => {
    if (data) {
      return data.reduce((acc, current) => {
        const { ...item } = current
        acc[current.code] = {
          ...item,
          value:
            current.code === 'AUTO_APPROVAL'
              ? item.value === '1'
                ? true
                : false
              : item.value,
        }
        return acc
      }, {})
    }

    return {}
  }, [data])

  useEffect(() => {
    refreshData()
    return () => {
      actGetSettingAutoApproval()
    }
  }, [])
  const refreshData = () => {
    actGetSettingAutoApproval({})
  }

  const onSubmit = (values) => {
    const dataSubmit = data.map((item) => ({
      code: item.code,
      value:
        values[item.code].code === 'AUTO_APPROVAL'
          ? values[item.code].value === true
            ? '1'
            : '0'
          : values[item.code].value,
    }))
    actUpdateSettingAutoApproval({ data: dataSubmit }, refreshData)
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.settingAutoApproval')}
      loading={isLoading || isLoadingUpdate}
      onBack={() => history.goBack()}
    >
      {!isEmpty(data) && (
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ values }) => (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  mb: 1,
                  justifyContent: 'space-between',
                }}
              >
                <Box></Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                >
                  <Button
                    type="submit"
                    sx={{ marginRight: '10px' }}
                    color="success"
                    variant="outlined"
                  >
                    {t('actionBar.saveInfo')}
                  </Button>
                </Box>
              </Box>
              <Divider />
              {_.sortBy(data, 'id')?.map((item, idx) => (
                <Box
                  sx={{ marginTop: idx === 0 ? '20px' : '30px' }}
                  key={item.id}
                >
                  <SettingForm
                    t={t}
                    data={item}
                    name={item.code}
                    values={values}
                  />
                </Box>
              ))}
            </Form>
          )}
        </Formik>
      )}
    </Page>
  )
}

export default SettingAutoApproval
