import * as Yup from 'yup'

export const validationSchema = (t) =>
  Yup.object().shape({
    code: Yup.string().required(t('form.required')),
    name: Yup.string().required(t('form.required')),
    lat: Yup.string().nullable(),
    lng: Yup.string().nullable(),
    location: Yup.string().nullable(),
    timeDeparture: Yup.string().test(_, (value, context) => {
      if (!value || value.includes('-')) {
        return context.createError({
          path: context.path,
          message: t('form.required'),
        })
      }

      if (!validateTime(value, context.parent?.timeArrival)) {
        return context.createError({
          path: context.path,
          message: t('pickupPoint.form.rangeTimeError'),
        })
      }

      return true
    }),
    timeArrival: Yup.string().test(_, (value, context) => {
      if (!value || value.includes('-')) {
        return context.createError({
          path: context.path,
          message: t('form.required'),
        })
      }

      if (!validateTime(context.parent?.timeDeparture, value)) {
        return context.createError({
          path: context.path,
          message: t('pickupPoint.form.rangeTimeError'),
        })
      }

      return true
    }),
    isActive: Yup.number().nullable().required(t('form.required')),
  })

const validateTime = (timeDeparture = '', timeArrival = '') => {
  const [departureHour, departureMinute] = timeDeparture.split(':')
  const [arrivalHour, arrivalMinute] = timeArrival.split(':')

  if (Number(departureHour) > Number(arrivalHour)) {
    return false
  } else if (Number(departureHour) === Number(arrivalHour)) {
    if (Number(departureMinute) >= Number(arrivalMinute)) {
      return false
    }
  }
  return true
}
