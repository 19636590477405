import { all } from 'redux-saga/effects'

import watchAttendance from './attendance'
import watchAttendanceNotExcludingHoliday from './attendance-not-excluding-holiday'
import watchBusinessTravel from './business-travel'
import watchCatShift from './cat-shift'
import watchEmail from './email'
import watchFactory from './factory'
import watchOrgStructure from './org-structure'
import watchOvertime from './overtime'
import watchPickupPoint from './pickup-point'
import watchPosition from './position'
import watchProfileCustom from './profile-custom'
import watchReportAll from './report-all'
import watchReportBr11 from './report-br11'
import watchReportBusNotCheckIn from './report-bus-not-check-in'
import watchReportBusinessTravelNotCheckIn from './report-business-travel-not-check-in'
import watchReportCheckInEmployee from './report-check-in-employee'
import watchReportFee from './report-fee'
import watchReportOt from './report-ot'
import watchRosterCalendar from './roster-calendar'
import watchRoute from './route'
import watchRoutePointRegister from './route-point-register'
import watchSetting from './setting'
import watchSettingAutoApproval from './setting-auto-approval'
import watchVehicleCategories from './vehicle-categories'
import watchVehicleRegister from './vehicle-register'
import watchWorkSchedule from './work-schedule'

/**
 * Root saga
 */
export default function* sagas() {
  yield all([
    watchOrgStructure(),
    watchProfileCustom(),
    watchOvertime(),
    watchPickupPoint(),
    watchRoute(),
    watchWorkSchedule(),
    watchCatShift(),
    watchBusinessTravel(),
    watchFactory(),
    watchAttendance(),
    watchVehicleCategories(),
    watchReportBr11(),
    watchPosition(),
    watchRosterCalendar(),
    watchReportAll(),
    watchReportOt(),
    watchSetting(),
    watchEmail(),
    watchReportFee(),
    watchVehicleRegister(),
    watchRoutePointRegister(),
    watchReportCheckInEmployee(),
    watchReportBusinessTravelNotCheckIn(),
    watchReportBusNotCheckIn(),
    watchSettingAutoApproval(),
    watchAttendanceNotExcludingHoliday(),
  ])
}
