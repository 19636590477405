// Action: Get list
export const MASTER_GET_LIST_ROUTE_POINT_REGISTER =
  'MASTER_GET_LIST_ROUTE_POINT_REGISTER'
export const MASTER_GET_LIST_ROUTE_POINT_REGISTER_SUCCESS =
  'MASTER_GET_LIST_ROUTE_POINT_REGISTER_SUCCESS'
export const MASTER_GET_LIST_ROUTE_POINT_REGISTER_FAILED =
  'MASTER_GET_LIST_ROUTE_POINT_REGISTER_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListRoutePointRegister(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_ROUTE_POINT_REGISTER,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListRoutePointRegisterSuccess(payload) {
  return {
    type: MASTER_GET_LIST_ROUTE_POINT_REGISTER_SUCCESS,
    payload: payload,
  }
}

export function actGetListRoutePointRegisterFailed() {
  return {
    type: MASTER_GET_LIST_ROUTE_POINT_REGISTER_FAILED,
  }
}



export default {
  actGetListRoutePointRegister,
  actGetListRoutePointRegisterSuccess,
  actGetListRoutePointRegisterFailed,
}
