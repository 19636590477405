/*eslint no-param-reassign: "error"*/

import { FormLabel, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useApp } from '~/common/hooks/useApp'
import { Field } from '~/components/Formik'

const SettingForm = ({ t, name, data }) => {
  const { canAccess } = useApp()

  const getConfigName = (code) => {
    switch (code) {
      case 'AUTO_APPROVAL':
        return t(`settingAutoApproval.AutoApproval`)
      case 'DISTANCE_VALID':
        return t(`settingAutoApproval.DistanceValid`)
      default:
        break
    }
  }
  return (
    <>
      <Grid
        container
        rowSpacing={2}
        sx={{
          backgroundColor: '#EFF5FB',
          padding: '24px',
          borderRadius: '8px',
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item lg={5.5} xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box mr={1}>
              <FormLabel sx={{ color: '#222' }}>
                {getConfigName(data.code)}
              </FormLabel>
            </Box>
            {data.code === 'AUTO_APPROVAL' && (
              <Field.Switch
                color="success"
                name={`${name}.value`}
                disabled={!canAccess(FUNCTION_CODE.USER_CREATE_SYSTEM_CONFIG)}
              />
            )}
            {data.code === 'DISTANCE_VALID' && (
              <Box
                width={100}
                display={'flex'}
                alignItems={'center'}
                gap={'3px'}
              >
                <Field.TextField name={`${name}.value`} />{' '}
                <Typography>m</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default SettingForm
