// Action: Get list
export const MASTER_GET_LIST_PROFILE_CUSTOM = 'MASTER_GET_LIST_PROFILE_CUSTOM'
export const MASTER_GET_LIST_PROFILE_CUSTOM_SUCCESS =
  'MASTER_GET_LIST_PROFILE_CUSTOM_SUCCESS'
export const MASTER_GET_LIST_PROFILE_CUSTOM_FAILED =
  'MASTER_GET_LIST_PROFILE_CUSTOM_FAILED'
export const MASTER_GET_LIST_PROFILE_CUSTOM_RESET =
  'MASTER_GET_LIST_PROFILE_CUSTOM_RESET'

// Action: Get detail
export const MASTER_GET_DETAIL_PROFILE_CUSTOM =
  'MASTER_GET_DETAIL_PROFILE_CUSTOM'
export const MASTER_GET_DETAIL_PROFILE_CUSTOM_SUCCESS =
  'MASTER_GET_DETAIL_PROFILE_CUSTOM_SUCCESS'
export const MASTER_GET_DETAIL_PROFILE_CUSTOM_FAILED =
  'MASTER_GET_DETAIL_PROFILE_CUSTOM_FAILED'
export const MASTER_GET_DETAIL_PROFILE_CUSTOM_RESET =
  'MASTER_GET_DETAIL_PROFILE_CUSTOM_RESET'

// Action: update
export const MASTER_UPDATE_PROFILE_CUSTOM = 'MASTER_UPDATE_PROFILE_CUSTOM'
export const MASTER_UPDATE_PROFILE_CUSTOM_SUCCESS =
  'MASTER_UPDATE_PROFILE_CUSTOM_SUCCESS'
export const MASTER_UPDATE_PROFILE_CUSTOM_FAILED =
  'MASTER_UPDATE_PROFILE_CUSTOM_FAILED'

// Action: sync
export const MASTER_SYNC_PROFILE_CUSTOM = 'MASTER_SYNC_PROFILE_CUSTOM'
export const MASTER_SYNC_PROFILE_CUSTOM_SUCCESS =
  'MASTER_SYNC_PROFILE_CUSTOM_SUCCESS'
export const MASTER_SYNC_PROFILE_CUSTOM_FAILED =
  'MASTER_SYNC_PROFILE_CUSTOM_FAILED'
export const MASTER_SYNC_PROFILE_CUSTOM_RESET =
  'MASTER_SYNC_PROFILE_CUSTOM_RESET'

//Action: cancel
export const MASTER_CANCEL_REGISTER_ROUTE = 'MASTER_CANCEL_REGISTER_ROUTE'
export const MASTER_CANCEL_REGISTER_ROUTE_SUCCESS =
  'MASTER_CANCEL_REGISTER_ROUTE_SUCCESS'
export const MASTER_CANCEL_REGISTER_ROUTE_FAILED =
  'MASTER_CANCEL_REGISTER_ROUTE_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetListProfileCustom(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_PROFILE_CUSTOM,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListProfileCustomSuccess(payload) {
  return {
    type: MASTER_GET_LIST_PROFILE_CUSTOM_SUCCESS,
    payload: payload,
  }
}

export function actGetListProfileCustomFailed() {
  return {
    type: MASTER_GET_LIST_PROFILE_CUSTOM_FAILED,
  }
}

export function actGetListProfileCustomReset() {
  return {
    type: MASTER_GET_LIST_PROFILE_CUSTOM_RESET,
  }
}

/** get detail
 * @param {
 *  id: any
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actGetDetailProfileCustom(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_DETAIL_PROFILE_CUSTOM,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetDetailProfileCustomSuccess(payload) {
  return {
    type: MASTER_GET_DETAIL_PROFILE_CUSTOM_SUCCESS,
    payload: payload,
  }
}

export function actGetDetailProfileCustomFailed() {
  return {
    type: MASTER_GET_DETAIL_PROFILE_CUSTOM_FAILED,
  }
}

export function actGetDetailProfileCustomReset() {
  return {
    type: MASTER_GET_DETAIL_PROFILE_CUSTOM_RESET,
  }
}

/** update
 * @param {
 *  id: any
 *  body: object
 * } payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actUpdateProfileCustom(payload, onSuccess, onError) {
  return {
    type: MASTER_UPDATE_PROFILE_CUSTOM,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actUpdateProfileCustomSuccess(payload) {
  return {
    type: MASTER_UPDATE_PROFILE_CUSTOM_SUCCESS,
    payload: payload,
  }
}

export function actUpdateProfileCustomFailed() {
  return {
    type: MASTER_UPDATE_PROFILE_CUSTOM_FAILED,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncProfileCustom(payload, onSuccess, onError) {
  return {
    type: MASTER_SYNC_PROFILE_CUSTOM,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncProfileCustomSuccess(payload) {
  return {
    type: MASTER_SYNC_PROFILE_CUSTOM_SUCCESS,
    payload: payload,
  }
}

export function actSyncProfileCustomFailed() {
  return {
    type: MASTER_SYNC_PROFILE_CUSTOM_FAILED,
  }
}

export function actSyncProfileCustomReset() {
  return {
    type: MASTER_SYNC_PROFILE_CUSTOM_RESET,
  }
}

/** cancel register route
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */

export function actCancelRegisterRoute(payload, onSuccess, onError) {
  return {
    type: MASTER_CANCEL_REGISTER_ROUTE,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actCancelRegisterRouteSuccess() {
  return {
    type: MASTER_CANCEL_REGISTER_ROUTE_SUCCESS,
  }
}

export function actCancelRegisterRouteFailed() {
  return {
    type: MASTER_CANCEL_REGISTER_ROUTE_FAILED,
  }
}
export default {
  actGetListProfileCustom,
  actGetListProfileCustomSuccess,
  actGetListProfileCustomFailed,
  actGetListProfileCustomReset,
  actGetDetailProfileCustom,
  actGetDetailProfileCustomSuccess,
  actGetDetailProfileCustomFailed,
  actGetDetailProfileCustomReset,
  actUpdateProfileCustom,
  actUpdateProfileCustomSuccess,
  actUpdateProfileCustomFailed,
  actSyncProfileCustom,
  actSyncProfileCustomSuccess,
  actSyncProfileCustomFailed,
  actSyncProfileCustomReset,
  actCancelRegisterRoute,
  actCancelRegisterRouteSuccess,
  actCancelRegisterRouteFailed,
}
