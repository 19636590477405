import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import actionVehicleRegister from '~/modules/master/redux/actions/vehicle-register'

const useVehicleRegister = () => {
  const data = useSelector((state) => get(state, 'master.vehicleRegister'))

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(actionVehicleRegister, dispatch),
    [dispatch],
  )
  return {
    actions,
    data,
  }
}

export default useVehicleRegister 