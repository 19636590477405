import { api } from '~/services/api'

/**
 * @param {any} params Params will be sent to server
 * @returns {Promise}
 */
export const apiGetListAttendanceNotExcludingHoliday = (params) => {
  const uri = `/human-resource/attendance-exclude-dayoff`
  return api.get(uri, params)
}
/**
 * @returns {Promise}
 */
export const apiSyncAttendanceNotExcludingHoliday = (body = {}) => {
  const uri = `/sync/roster-calendar-exclude-dayoff?dateStart=${body.dateStart}&dateEnd=${body.dateEnd}`

  return api.post(uri)
}
