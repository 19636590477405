import { useMemo } from 'react'

import { Formik } from 'formik'

import Dialog from '~/components/Dialog'

import FormInput from './form-input'

const DEFAULT_DATA = {
  reason: "",
}

const ConfirmReject = ({
  t,
  open,
  data,
  onClose = () => {},
  listData,
  multiple,
  handleReject
}) => {

  const initialValues = useMemo(() => {  
      return {
        ...DEFAULT_DATA,
      }
  }, [data])
  const handleSubmit = async (val, { setFieldValue }) => {
    const body = {
      ...val,
    }
    setFieldValue('isLoading', true)
    if(multiple){
      handleReject(listData,multiple,body.reason)
    }else {
      handleReject(data,multiple,body.reason)
    }
    // handleReject(body.reason)
    setFieldValue('isLoading', false)
  }

  return (
    <Dialog
      open={open}
      title={t('RoutePointRegister.reason')
      }
      onCancel={onClose}
      maxWidth="sm"
      noBorderBottom
    >
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        enableReinitialize
      >
        {({ values }) => (
          <FormInput
            t={t}
            data={data}
            isLoading={values.isLoading}
            onClose={onClose}
          />
        )}
      </Formik>
    </Dialog>
  )
}

export default ConfirmReject
