import { useMemo, useRef, useState } from 'react'

import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { Form, Formik } from 'formik'
import { isEmpty } from 'lodash'

import {
  MODAL_MODE,
  NOTIFICATION_TYPE,
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import Dialog from '~/components/Dialog'
import { Field } from '~/components/Formik'
// import TaskBar from '~/components/TaskBar'
import Loading from '~/components/Loading'
import { STATUS_OPTIONS, STATUS_VALUE } from '~/modules/master/constants'
import {
  apiCreatePickupPoint,
  apiUpdatePickupPoint,
} from '~/modules/master/redux/apis/pickup-point'
import addNotification from '~/utils/toast'

import MapComponent from './map'
import { validationSchema } from './schema'

const DEFAULT_DATA = {
  isActive: STATUS_VALUE.active,
  code: '',
  name: '',
  timeDeparture: '--:--',
  timeArrival: '--:--',
  location: '',
  lat: '',
  lng: '',
}

const CreateUpdate = ({
  t,
  open,
  data = {},
  onClose = () => {},
  handleSubmitSuccess = () => {},
  // openConfirmDelete = () => {},
}) => {
  const formikRef = useRef(null)
  const [searchResult, setSearchResult] = useState(null)
  const mode = isEmpty(data)
    ? MODAL_MODE.CREATE
    : data.id
    ? MODAL_MODE.UPDATE
    : MODAL_MODE.CLONE

  const initialValues = useMemo(() => {
    if (data && Object.keys(data)?.length) {
      const timeDeparture = data.timeDeparture?.split(':')
      const timeArrival = data.timeArrival?.split(':')

      const dataInit = {
        ...DEFAULT_DATA,
        ...data,
        lat: data.lat?.toString() || '',
        lng: data.lng?.toString() || '',
        timeDeparture:
          timeDeparture && Number(timeDeparture[0]) < 10
            ? `0${Number(timeDeparture[0])}:${timeDeparture[1]}`
            : data.timeDeparture,
        timeArrival:
          timeDeparture && Number(timeArrival[0]) < 10
            ? `0${Number(timeArrival[0])}:${timeArrival[1]}`
            : data.timeArrival,
      }
      setSearchResult({
        lat: dataInit.lat,
        lng: dataInit.lng,
        display_name: dataInit.location,
      })
      return dataInit
    }
    return DEFAULT_DATA
  }, [data])

  const handleSubmit = async (val, { setFieldValue }) => {
    const body = {
      ...val,
    }
    setFieldValue('isLoading', true)
    let res = null
    if (body?.id) {
      res = await apiUpdatePickupPoint({ body })
    } else {
      res = await apiCreatePickupPoint({ body })
    }

    setFieldValue('isLoading', false)

    if (res?.statusCode === 200) {
      addNotification(
        `pickupPoint.notification.${
          mode === MODAL_MODE.UPDATE ? 'updateSuccess' : 'createSuccess'
        }`,
        NOTIFICATION_TYPE.SUCCESS,
      )
      handleSubmitSuccess()
    } else {
      addNotification(res?.message, NOTIFICATION_TYPE.ERROR)
    }
  }
  const handleSearch = async (value, formik) => {
    if (!value.location) return
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?` +
          `format=json&` +
          `q=${encodeURIComponent(value.location)}`,
      )

      const data = await response.json()

      if (data.length > 0) {
        const location = data[0]
        const lat = parseFloat(location.lat)
        const lng = parseFloat(location.lon)
        formik.setFieldValue('lat', lat.toString())

        formik.setFieldValue('lng', lng.toString())
        setSearchResult({ lat, lng, display_name: location.display_name })
      }
    } catch (error) {}
  }
  return (
    <Dialog
      open={open}
      title={t(
        `pickupPoint.form.${mode === MODAL_MODE.UPDATE ? 'update' : 'create'}`,
      )}
      onCancel={() => {
        setSearchResult(null)

        if (formikRef.current) {
          formikRef.current.resetForm()
          formikRef.current.setValues(DEFAULT_DATA)
        }

        onClose()
      }}
      maxWidth="lg"
      noBorderBottom
    >
      {/* <TaskBar sx={{ background: 'white' }} left={[]} right={actionHeader} />
      {mode === MODAL_MODE.UPDATE && (
        <Divider sx={{ borderColor: '#CBCBCB', margin: '0 0 20px 0' }} />
      )} */}

      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema(t)}
        enableReinitialize
      >
        {(formik) => {
          formikRef.current = formik
          return (
            <Form>
              <Grid
                container
                rowSpacing={4 / 3}
                sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
                justifyContent="space-between"
              >
                <Grid item xs={12}>
                  <Field.TextField
                    name="code"
                    placeholder={t('pickupPoint.form.code')}
                    label={t('pickupPoint.form.code')}
                    inputProps={{
                      maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }}
                    allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
                    disabled={
                      mode === MODAL_MODE.UPDATE &&
                      data.isActive === STATUS_VALUE.active
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field.TextField
                    name="name"
                    placeholder={t('pickupPoint.form.name')}
                    label={t('pickupPoint.form.name')}
                    inputProps={{
                      maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                    }}
                    required
                  />
                </Grid>
                <Grid item lg={5} xs={12}>
                  <Field.TimePicker
                    name="timeDeparture"
                    label={t('pickupPoint.timeDeparture')}
                    hideClockIcon
                    required
                  />
                </Grid>
                <Grid item lg={5} xs={12}>
                  <Field.TimePicker
                    name="timeArrival"
                    label={t('pickupPoint.timeArrival')}
                    hideClockIcon
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display={'flex'} gap={2}>
                    <Field.TextField
                      name="location"
                      placeholder={t('pickupPoint.location')}
                      label={t('pickupPoint.location')}
                      inputProps={{
                        maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
                      }}
                    />
                    <Button
                      type="button"
                      sx={{ marginRight: '10px' }}
                      color="grayF4"
                      onClick={() => handleSearch(formik.values, formik)}
                    >
                      {t('Search')}
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={5} xs={12}>
                  <Field.TextField
                    name="lat"
                    label={t('pickupPoint.latitude')}
                    placeholder={t('pickupPoint.latitude')}
                    disabled
                  />
                </Grid>
                <Grid item lg={5} xs={12}>
                  <Field.TextField
                    name="lng"
                    label={t('pickupPoint.longitude')}
                    placeholder={t('pickupPoint.longitude')}
                    disabled
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Field.Autocomplete
                    label={t('pickupPoint.isActive')}
                    name="isActive"
                    options={STATUS_OPTIONS}
                    getOptionLabel={(opt) => t(opt?.text) || ''}
                    getOptionValue={(opt) => opt?.id}
                    isOptionEqualToValue={(opt, val) =>
                      opt?.id === (val?.id || val)
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Box width="100%" height="300px" pb={3}>
                <MapComponent searchResult={searchResult} formik={formik} />
              </Box>

              <Box sx={{ padding: 3 }}>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type="button"
                      sx={{ marginRight: '10px' }}
                      color="grayF4"
                      onClick={formik.handleReset}
                    >
                      {t('actionBar.cancel')}
                    </Button>
                    <Button type="submit" sx={{ marginRight: '10px' }}>
                      {t(
                        `actionBar.${
                          mode === MODAL_MODE.UPDATE ? 'save' : 'create'
                        }`,
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Box>
              <Loading open={formik.values.isLoading}></Loading>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default CreateUpdate
