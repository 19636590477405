import React, { useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/system'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { FUNCTION_CODE } from '~/common/constants/functionCode'
import { useQueryState } from '~/common/hooks'
import { useApp } from '~/common/hooks/useApp'
import DataTable from '~/components/DataTable'
import FilterArea from '~/components/FilterArea'
import Guard from '~/components/Guard'
import HotKeys from '~/components/HotKeys'
import IconButton from '~/components/IconButton'
import ImportExport from '~/components/ImportExport'
import Page from '~/components/Page'
import PopupConfirm from '~/components/PopupConfirm'
import Status from '~/components/Status'
import Tabs from '~/components/Tabs'
import TaskBar from '~/components/TaskBar'
import TextLink from '~/components/TextLink'
import { EXPORT_TYPE, STATUS_OPTIONS } from '~/modules/master/constants'
import {
  apiExport,
  apiGetTemplate,
  apiImport,
} from '~/modules/master/redux/apis/import-export'
import useRoute from '~/modules/master/redux/hooks/useRoute'
import { ROUTE } from '~/modules/master/routes/config'
import { convertFilterParams, convertSortParams } from '~/utils'

import { tabList } from '../constants'
import FilterForm from './filter-form'

const breadcrumbs = [
  {
    route: ROUTE.ROUTE.LIST.PATH,
    title: ROUTE.ROUTE.LIST.TITLE,
  },
]

const ListRoute = () => {
  const { t } = useTranslation(['buseye'])
  const history = useHistory()
  const { canAccess } = useApp()

  const DEFAULT_FILTERS = {
    code: '',
    name: '',
    costCenterIds: [],
    status: null,
  }

  const [selectedRows, setSelectedRows] = useState([])
  const [deleteItems, setDeleteItems] = useState(null)
  const [changeStatus, setChangeStatus] = useState(null)

  const {
    page,
    pageSize,
    sort,
    filters,
    tab,
    setPage,
    setPageSize,
    setSort,
    setFilters,
    selectedRowsDeps,
    setTab,
  } = useQueryState()

  const {
    data: {
      listRoute: { data, total, isLoading },
      updateRoute: { isLoading: isLoadingUpdate },
    },
    actions: { actGetListRoute, actDeleteRoute, actChangeStatusRoute },
  } = useRoute()

  useEffect(() => {
    refreshData()
  }, [page, pageSize, sort, filters, tab])

  useEffect(() => {
    setSelectedRows([])
  }, [selectedRowsDeps, tab])

  const columns = useMemo(() => [
    {
      field: 'code',
      headerName: t('route.code'),
      width: 80,
      visible: 'always',
      sortable: true,
      renderCell: (params) => {
        const { code, id } = params?.row
        return <TextLink text={code} onClick={() => handleClickDetail(id)} />
      },
    },
    {
      field: 'name',
      headerName: t('route.name'),
      width: 150,
      sortable: true,
    },
    {
      field: 'timeDeparture',
      headerName: t('route.timeDeparture'),
      width: 150,
      renderCell: (params) => {
        const { pickupPoints } = params.row
        return pickupPoints[0]?.timeDeparture
      },
    },
    {
      field: 'pickupPoint',
      headerName: t('route.pickupPoint'),
      width: 150,
      sortable: true,
      renderCell: (params) => {
        const { pickupPoints } = params.row
        return pickupPoints[0]?.name
      },
    },
    {
      field: 'isActive',
      headerName: t('route.isActive'),
      width: 150,
      renderCell: (params) => {
        const { isActive } = params.row
        return (
          <Status options={STATUS_OPTIONS} value={isActive} variant="text" />
        )
      },
    },
    {
      field: 'action',
      headerName: t('common.action'),
      width: 150,
      renderCell: (params) => {
        const { isActive, id } = params.row

        return (
          <Box sx={{ display: 'flex' }}>
            <Guard code={FUNCTION_CODE.USER_UPDATE_ROUTE}>
              <IconButton
                icon="edit"
                tooltipText={t('common.update')}
                onClick={() => handleClickUpdate(params.row?.id)}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_CREATE_ROUTE}>
              <IconButton
                icon="clone"
                tooltipText={t('common.clone')}
                onClick={() => handleClickClone(params.row?.id)}
              />
            </Guard>
            <Guard code={FUNCTION_CODE.USER_CHANGE_STATUS_FACTORY_PERMISSION}>
              <IconButton
                icon={isActive ? 'locked' : 'unlock'}
                tooltipText={t('common.activePending')}
                onClick={() => openChangeStatus({ isActive, id })}
              />
            </Guard>
            {/* <Guard code={FUNCTION_CODE.USER_ACTIVE_ROUTE}>
              <IconButton
                icon={isActive ? 'locked' : 'unlock'}
                tooltipText={t('common.activePending')}
                onClick={() => openChangeStatus({ isActive, id })}
              />
            </Guard> */}

            <Guard code={FUNCTION_CODE.USER_DELETE_ROUTE}>
              <IconButton
                icon="delete"
                tooltipText={t('common.delete')}
                onClick={() => openConfirmDelete([params.row])}
              />
            </Guard>
          </Box>
        )
      },
    },
  ])

  const handleDelete = () => {
    if (!deleteItems) return
    actDeleteRoute({ codes: deleteItems.map((item) => item.code) }, refreshData)
  }

  const refreshData = () => {
    const { keyword = '', ...dataFilter } = filters
    const params = {
      keyword: keyword.trim(),
      page,
      limit: pageSize,
      filter: convertFilterParams(dataFilter, columns),
      sort: convertSortParams(sort),
    }
    actGetListRoute(params)
    closeConfirmDelete()
    closeChangeStatus()
    setSelectedRows([])
  }

  const handleClickCreateNew = () => {
    history.push(ROUTE.ROUTE.CREATE.PATH)
  }

  const handleClickDetail = (id) => {
    history.push(ROUTE.ROUTE.DETAIL.PATH.replace(':id', `${id}`))
  }

  const handleClickUpdate = (id) => {
    history.push(ROUTE.ROUTE.UPDATE.PATH.replace(':id', `${id}`))
  }

  const handleClickClone = (id) => {
    history.push(`${ROUTE.ROUTE.CREATE.PATH}?cloneId=${id}`)
  }

  const tabElement = (
    <Tabs
      list={tabList(t, {
        all: total,
      })}
      value={tab}
      onChange={setTab}
    />
  )

  let actionHeader = [
    <Guard code={FUNCTION_CODE.USER_CREATE_ROUTE}>
      <IconButton
        icon="add"
        title={t('common.create')}
        onClick={handleClickCreateNew}
        sx={{ marginRight: '10px' }}
      />
    </Guard>,
  ]

  if (selectedRows?.length > 0) {
    actionHeader = [
      ...actionHeader,
      <Guard code={FUNCTION_CODE.USER_CHANGE_STATUS_ROUTE_PERMISSION}>
        <IconButton
          icon="locked"
          title={`${t('common.active')}/ ${t('common.pending')}`}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openChangeStatus(selectedRows)}
        />
      </Guard>,
      <Guard code={FUNCTION_CODE.USER_DELETE_ROUTE}>
        <IconButton
          icon="delete"
          title={t('common.delete')}
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={() => openConfirmDelete(selectedRows)}
        />
      </Guard>,
    ]
  }

  const openConfirmDelete = (val) => {
    setDeleteItems(val)
  }

  const closeConfirmDelete = () => {
    setDeleteItems(null)
  }

  const openChangeStatus = (val) => {
    if (Array.isArray(val) && val.length > 0) {
      const status = val[0].isActive
      const checkUpdate = val.some((item) => item.isActive !== status)
      if (checkUpdate) return
    }
    setChangeStatus(val)
  }

  const closeChangeStatus = () => {
    setChangeStatus(null)
  }

  const handlChangeStatus = () => {
    if (Array.isArray(changeStatus) && changeStatus.length > 0) {
      const status = changeStatus[0].isActive
      const codes = changeStatus.map((item) => item.code)
      actChangeStatusRoute({ params: { codes }, isActive: status }, refreshData)
    } else {
      actChangeStatusRoute(
        { params: changeStatus.id, isActive: changeStatus.isActive },
        refreshData,
      )
    }
  }

  return (
    <Page
      breadcrumbs={breadcrumbs}
      title={t('menu.route')}
      loading={isLoading || isLoadingUpdate}
      onBack={() => history.goBack()}
    >
      <HotKeys handlers={{}} />
      <TaskBar
        left={actionHeader}
        right={[
          <ImportExport
            name={t('route.fileNameExport')}
            onImport={
              canAccess(FUNCTION_CODE.USER_IMPORT_ROUTE)
                ? (body) => apiImport({ body, type: EXPORT_TYPE.ROUTE })
                : null
            }
            importMessage={{
              success: t('route.notification.importSuccess'),
            }}
            onExport={
              canAccess(FUNCTION_CODE.USER_EXPORT_ROUTE)
                ? () => {
                    const params = {
                      filter: convertFilterParams(filters, [
                        { field: 'createdAt', filterFormat: 'date' },
                      ]),
                      sort: convertSortParams(sort),
                      type: EXPORT_TYPE.ROUTE,
                    }
                    if (!isEmpty(selectedRows)) {
                      params.ids = JSON.stringify(
                        selectedRows?.map((x) => ({ id: x?.id })),
                      )
                    }

                    return apiExport(params)
                  }
                : null
            }
            onDownloadTemplate={() => apiGetTemplate(EXPORT_TYPE.ROUTE)}
            onRefresh={refreshData}
          />,
        ]}
      />
      <Guard code={FUNCTION_CODE.USER_SEARCH_ROUTE}>
        <FilterArea
          values={filters}
          onApply={setFilters}
          quickFilters={{
            form: <FilterForm />,
            defaultValues: DEFAULT_FILTERS,
          }}
        />
      </Guard>
      <DataTable
        rows={data || []}
        pageSize={pageSize}
        page={page}
        columns={columns}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        onSortChange={setSort}
        onSelectionChange={setSelectedRows}
        selected={selectedRows}
        total={total}
        title={t('master.title')}
        sort={sort}
        tabs={tabElement}
      />
      <PopupConfirm
        t={t}
        title={t('route.modalChangeStatus.title')}
        content={t('route.modalChangeStatus.content')}
        open={!!changeStatus}
        onConfirm={handlChangeStatus}
        onCancel={closeChangeStatus}
      />
      <PopupConfirm
        t={t}
        title={t('route.modalDelete.title')}
        content={t('route.modalDelete.content')}
        open={!!deleteItems}
        onConfirm={handleDelete}
        onCancel={closeConfirmDelete}
      />
    </Page>
  )
}

export default ListRoute
