
export const TAB_VALUE = {
  all: 0,
  waitingApproval: 1,
  approved: 2,
  reject: 3,
}

export const tabList = (t, totals) => {
    const { all, waitingApproval, approved, reject } = totals
    return [
      {
        label: `${t('RoutePointRegister.all')} (${all})`,
        value: TAB_VALUE.all,
      },
      {
        label: `${t('RoutePointRegister.waitingApproval')} (${waitingApproval})`,
        value: TAB_VALUE.waitingApproval,
      },
      {
        label: `${t('RoutePointRegister.approved')} (${approved})`,
        value: TAB_VALUE.approved,
      },
      {
        label: `${t('RoutePointRegister.reject')} (${reject})`,
        value: TAB_VALUE.reject,
      },
    ]
  }

