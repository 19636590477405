import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { apiGetListRouteList } from '~/modules/master/redux/apis/route'

const FilterForm = ({ optionPickupPoint, getOptionPickupPoint }) => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={4}>
        <Field.DateRangePicker
          name="updatedAt"
          placeholder={t('RoutePointRegister.rangeDate')}
        />
      </Grid>

      <Grid item xs={4}>
        <Field.TextField
          name="empCode"
          placeholder={t('RoutePointRegister.employee')}
          inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <Icon name="search" />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={4}>
        <Field.Autocomplete
          name="routeCode"
          placeholder={t('RoutePointRegister.route')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListRouteList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          onChange={(e) => {
            if (e) {
              getOptionPickupPoint(e?.code)
            } else {
              getOptionPickupPoint()
            }
          }}
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => {
            return opt?.id === val?.id
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <Field.Autocomplete
          name="pickupPointCode"
          placeholder={t('RoutePointRegister.pointName')}
          labelWidth={70}
          options={optionPickupPoint}
          getOptionLabel={(opt) => t(opt?.label) || ''}
          getOptionValue={(opt) => {
            return opt?.value
          }}
          isOptionEqualToValue={(opt, val) =>
            opt?.value === (val?.value || val)
          }
        />
      </Grid>
    </>
  )
}

export default FilterForm
