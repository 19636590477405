export const TYPE_REGISTER = [
  {
    label: 'reportCheckInEmployee.BussinessTravel',
    value: 'BUSSINESS',
  },
  {
    label: 'reportCheckInEmployee.RouteTravel',
    value: 'ROUTE',
  },
]

export const OPTION_DIRECTION = [
  {
    label: 'reportCheckInEmployee.travelDirection',
    value: 'CHIEU_DI',
  },
  {
    label: 'reportCheckInEmployee.returnTravelDirection',
    value: 'CHIEU_VE',
  },
]

export const COORDINATES_STATUS = {
  CHECKIN_COORDINATES_INVALID: 'CHECKIN_COORDINATES_INVALID',
  PP_COORDINATES_INVALID: 'PP_COORDINATES_INVALID',
  DISTANCE_INVALID: 'DISTANCE_INVALID',
  DISTANCE_VALID: 'DISTANCE_VALID',
}

export const OPTION_CHECK_IN_STATUS = [
  {
    label: 'reportCheckInEmployee.ppCoordinatesInvalid',
    value: COORDINATES_STATUS.PP_COORDINATES_INVALID,
  },
  {
    label: 'reportCheckInEmployee.checkInCoordinatesInvalid',
    value: COORDINATES_STATUS.CHECKIN_COORDINATES_INVALID,
  },
  {
    label: 'reportCheckInEmployee.distanceInvalid',
    value: COORDINATES_STATUS.DISTANCE_INVALID,
  },
  {
    label: 'reportCheckInEmployee.distanceValid',
    value: COORDINATES_STATUS.DISTANCE_VALID,
  },
]
