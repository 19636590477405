import React from 'react'

import { Grid, InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import {
  ASYNC_SEARCH_LIMIT,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { apiGetListCatShiftList } from '~/modules/master/redux/apis/cat-shift'
import { apiGetListRouteList } from '~/modules/master/redux/apis/route'
import { convertFilterParams } from '~/utils'

import { CAT_SHIFT_LTF_CODE } from '../../report-all/constants'
import { OPTION_TYPE } from '../constants'

const FilterForm = ({ optionPickupPoint, getOptionPickupPoint }) => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Grid item xs={4}>
        <Field.DateRangePicker
          name="datetime"
          placeholder={t('reportBusinessTravelNotCheckIn.filter.dateGoing')}
        />
      </Grid>

      <Grid item xs={2}>
        <Field.Autocomplete
          name="codeShift"
          placeholder={t('reportBusinessTravelNotCheckIn.filter.shift')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListCatShiftList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
              filter: convertFilterParams({
                codes: CAT_SHIFT_LTF_CODE.join(','),
              }),
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        />
      </Grid>

      <Grid item xs={4}>
        <Field.TextField
          name="codeEmp"
          placeholder={t('reportBusinessTravelNotCheckIn.filter.employee')}
          inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 1 }}>
              <Icon name="search" />
            </InputAdornment>
          }
        />
      </Grid>

      <Grid item xs={4}>
        <Field.Autocomplete
          name="route"
          placeholder={t('reportBusinessTravelNotCheckIn.filter.route')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListRouteList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          onChange={(e) => {
            if (e) {
              getOptionPickupPoint(e?.code)
            } else {
              getOptionPickupPoint()
            }
          }}
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        />
      </Grid>

      <Grid item xs={4}>
        <Field.Autocomplete
          name="pickupPoint"
          placeholder={t('reportBusinessTravelNotCheckIn.filter.point')}
          labelWidth={70}
          options={optionPickupPoint}
          getOptionLabel={(opt) => t(opt?.label) || ''}
          getOptionValue={(opt) => {
            return opt?.value
          }}
          isOptionEqualToValue={(opt, val) =>
            opt?.value === (val?.value || val)
          }
        />
      </Grid>
      <Grid item xs={4}>
        <Field.Autocomplete
          name="type"
          placeholder={t('reportBusinessTravelNotCheckIn.filter.type')}
          labelWidth={70}
          options={OPTION_TYPE}
          getOptionLabel={(opt) => t(opt?.label) || ''}
          getOptionValue={(opt) => {
            return opt?.value
          }}
          isOptionEqualToValue={(opt, val) =>
            opt?.value === (val?.value || val)
          }
        />
      </Grid>
    </>
  )
}

export default FilterForm
