import React from 'react'

import { InputAdornment } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { ASYNC_SEARCH_LIMIT, TEXTFIELD_REQUIRED_LENGTH } from '~/common/constants'
import { Field } from '~/components/Formik'
import Icon from '~/components/Icon'
import { STATUS_OPTIONS } from '~/modules/master/constants'
import { apiGetListRouteList } from '~/modules/master/redux/apis/route'

const FilterForm = () => {
  const { t } = useTranslation(['buseye'])
  return (
    <>
      <Field.TextField
        name="keyword"
        placeholder={t('common.inputKeyWord')}
        inputProps={{ maxLength: TEXTFIELD_REQUIRED_LENGTH.CODE.MAX }}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            <Icon name="search" />
          </InputAdornment>
        }
      />

      <div style={{ marginLeft: '10px' }}>
        <Field.Autocomplete
          name="codeRoute"
          placeholder={t('reportBr11.filter.codeRoute')}
          labelWidth={70}
          asyncRequest={(s) =>
            apiGetListRouteList({
              keyword: s,
              limit: ASYNC_SEARCH_LIMIT,
            })
          }
          asyncRequestHelper={(res) => res?.data?.items}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(opt, val) => opt?.id === val?.id}
        />
      </div>

      <div style={{ marginLeft: '10px' }}>
        <Field.Autocomplete
          label={t('pickupPoint.isActive')}
          placeholder={t('pickupPoint.isActive')}
          name="isActive"
          options={STATUS_OPTIONS}
          getOptionLabel={(opt) => t(opt?.text) || ''}
          getOptionValue={(opt) => opt?.id}
          isOptionEqualToValue={(opt, val) => opt?.id === (val?.id || val)}
        />
      </div>
    </>
  )
}

export default FilterForm
