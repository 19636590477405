// Action: Get list
export const MASTER_GET_LIST_VEHICLE_REGISTER =
  'MASTER_GET_LIST_VEHICLE_REGISTER'
export const MASTER_GET_LIST_VEHICLE_REGISTER_SUCCESS =
  'MASTER_GET_LIST_VEHICLE_REGISTER_SUCCESS'
export const MASTER_GET_LIST_VEHICLE_REGISTER_FAILED =
  'MASTER_GET_LIST_VEHICLE_REGISTER_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListVehicleRegister(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_VEHICLE_REGISTER,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListVehicleRegisterSuccess(payload) {
  return {
    type: MASTER_GET_LIST_VEHICLE_REGISTER_SUCCESS,
    payload: payload,
  }
}

export function actGetListVehicleRegisterFailed() {
  return {
    type: MASTER_GET_LIST_VEHICLE_REGISTER_FAILED,
  }
}



export default {
  actGetListVehicleRegister,
  actGetListVehicleRegisterSuccess,
  actGetListVehicleRegisterFailed,
}
