/* eslint-disable import/order */
import { Box } from '@mui/material'
import 'ol/ol.css'
import React, { useEffect, useRef, useState } from 'react'

const MapComponent = ({ searchResult, formik }) => {
  const mapRef = useRef()
  const [map, setMap] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const vectorSourceRef = useRef(null)

  const getAddressFromCoords = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?` +
          `format=json&` +
          `lat=${lat}&` +
          `lon=${lng}`,
      )
      const data = await response.json()
      return data.display_name
    } catch (error) {
      return ''
    }
  }

  useEffect(() => {
    const view = new window.ol.View({
      center: window.ol.proj.fromLonLat([106.629662, 10.823099]),
      zoom: 15,
    })

    const osmLayer = new window.ol.layer.Tile({
      source: new window.ol.source.OSM(),
    })

    const vectorSource = new window.ol.source.Vector()
    vectorSourceRef.current = vectorSource
    const vectorLayer = new window.ol.layer.Vector({
      source: vectorSource,
    })

    const mapInstance = new window.ol.Map({
      target: mapRef.current,
      layers: [osmLayer, vectorLayer],
      view: view,
    })

    mapInstance.on('click', async (event) => {
      const coords = window.ol.proj.transform(
        event.coordinate,
        'EPSG:3857',
        'EPSG:4326',
      )
      const lat = coords[1]
      const lng = coords[0]

      const address = await getAddressFromCoords(lat, lng)

      setSelectedLocation({
        lng,
        lat,
        display_name: address,
      })

      vectorSource.clear()
      const feature = new window.ol.Feature({
        geometry: new window.ol.geom.Point(event.coordinate),
      })

      feature.setStyle(
        new window.ol.style.Style({
          image: new window.ol.style.Circle({
            radius: 7,
            fill: new window.ol.style.Fill({ color: '#ff0000' }),
            stroke: new window.ol.style.Stroke({
              color: '#ffffff',
              width: 2,
            }),
          }),
        }),
      )

      vectorSource.addFeature(feature)
    })

    setMap(mapInstance)

    return () => {
      if (mapInstance) {
        mapInstance
          .getLayers()
          .getArray()
          .slice()
          .forEach((layer) => {
            mapInstance.removeLayer(layer)
          })

        if (vectorSourceRef.current) {
          vectorSourceRef.current.clear()
        }

        mapInstance
          .getInteractions()
          .getArray()
          .slice()
          .forEach((interaction) => {
            mapInstance.removeInteraction(interaction)
          })

        mapInstance
          .getControls()
          .getArray()
          .slice()
          .forEach((control) => {
            mapInstance.removeControl(control)
          })

        mapInstance
          .getOverlays()
          .getArray()
          .slice()
          .forEach((overlay) => {
            mapInstance.removeOverlay(overlay)
          })

        mapInstance.setTarget(undefined)

        setMap(null)
        setSelectedLocation(null)
        vectorSourceRef.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (searchResult && map && vectorSourceRef.current) {
      const coords = [searchResult.lng, searchResult.lat]

      map.getView().animate({
        center: window.ol.proj.fromLonLat([
          searchResult.lng || 106.629662,
          searchResult.lat || 10.823099,
        ]),
        zoom: 15,
        duration: 1000,
      })

      vectorSourceRef.current.clear()
      const feature = new window.ol.Feature({
        geometry: new window.ol.geom.Point(window.ol.proj.fromLonLat(coords)),
      })

      feature.setStyle(
        new window.ol.style.Style({
          image: new window.ol.style.Circle({
            radius: 7,
            fill: new window.ol.style.Fill({ color: '#ff0000' }),
            stroke: new window.ol.style.Stroke({
              color: '#ffffff',
              width: 2,
            }),
          }),
        }),
      )

      vectorSourceRef.current.addFeature(feature)
      setSelectedLocation({
        ...searchResult,
        lng: coords[0],
        lat: coords[1],
      })
    }
  }, [searchResult, map])

  useEffect(() => {
    if (selectedLocation) {
      formik.setFieldValue('lat', selectedLocation.lat.toString())
      formik.setFieldValue('lng', selectedLocation.lng.toString())
      if (selectedLocation.display_name) {
        formik.setFieldValue('location', selectedLocation.display_name)
      }
    }

    return () => {
      if (selectedLocation) {
        formik.setFieldValue('lat', '')
        formik.setFieldValue('lng', '')
        if (selectedLocation.display_name) {
          formik.setFieldValue('location', '')
        }
      }
    }
  }, [selectedLocation])

  useEffect(() => {
    if (searchResult) {
      formik?.setFieldValue('location', searchResult?.display_name?.toString())
    }
  }, [searchResult])
  return (
    <Box width="100%" height="100%">
      <div
        ref={mapRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: '#f8f9fa',
        }}
      />
    </Box>
  )
}

export default MapComponent
