import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import actionAttendanceNotExcludingHoliday from '~/modules/master/redux/actions/attendance-not-excluding-holiday'

const useAttendanceNotExcludingHoliday = () => {
  const data = useSelector((state) =>
    get(state, 'master.attendanceNotExcludingHoliday'),
  )

  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators(actionAttendanceNotExcludingHoliday, dispatch),
    [dispatch],
  )
  return {
    actions,
    data,
  }
}

export default useAttendanceNotExcludingHoliday
