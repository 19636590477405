export const STATUS_SYNC = {
  SUCCESS: 1,
  ERROR: 0,
}

export const STATUS_SYNC_OPTIONS = [
  {
    id: STATUS_SYNC.ERROR,
    text: 'common.error',
    color: 'rejected',
  },
  {
    id: STATUS_SYNC.SUCCESS,
    text: 'common.success',
    color: 'active',
  },
]
