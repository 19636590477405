// Action: Get list
export const MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY =
  'MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY'
export const MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS =
  'MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS'
export const MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED =
  'MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED'

// Action: sync
export const MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY =
  'MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY'
export const MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS =
  'MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS'
export const MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED =
  'MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED'
export const MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_RESET =
  'MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_RESET'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListAttendanceNotExcludingHoliday(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListAttendanceNotExcludingHolidaySuccess(payload) {
  return {
    type: MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS,
    payload: payload,
  }
}

export function actGetListAttendanceNotExcludingHolidayFailed() {
  return {
    type: MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED,
  }
}

/** sync
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
export function actSyncAttendanceNotExcludingHoliday(
  payload,
  onSuccess,
  onError,
) {
  return {
    type: MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actSyncAttendanceNotExcludingHolidaySuccess(payload) {
  return {
    type: MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS,
    payload: payload,
  }
}

export function actSyncAttendanceNotExcludingHolidayFailed() {
  return {
    type: MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED,
  }
}

export function actSyncAttendanceNotExcludingHolidayReset() {
  return {
    type: MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_RESET,
  }
}

export default {
  actGetListAttendanceNotExcludingHoliday,
  actGetListAttendanceNotExcludingHolidaySuccess,
  actGetListAttendanceNotExcludingHolidayFailed,
  actSyncAttendanceNotExcludingHoliday,
  actSyncAttendanceNotExcludingHolidaySuccess,
  actSyncAttendanceNotExcludingHolidayFailed,
  actSyncAttendanceNotExcludingHolidayReset,
}
