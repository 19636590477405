
export const TAB_VALUE = {
  all: 0,
  registed: 1,
  cancel: 2,
}

export const tabList = (t, totals) => {
    const { all, registed, cancel } = totals
    return [
      {
        label: `${t('vehicleRegister.all')} (${all})`,
        value: TAB_VALUE.all,
      },
      {
        label: `${t('vehicleRegister.registed')} (${registed})`,
        value: TAB_VALUE.registed,
      },
      {
        label: `${t('vehicleRegister.cancel')} (${cancel})`,
        value: TAB_VALUE.cancel,
      },
    ]
  }