// Action: Get list
export const MASTER_GET_LIST_BUS_NOT_CHECK_IN =
  'MASTER_GET_LIST_BUS_NOT_CHECK_IN'
export const MASTER_GET_LIST_BUS_NOT_CHECK_IN_SUCCESS =
  'MASTER_GET_LIST_BUS_NOT_CHECK_IN_SUCCESS'
export const MASTER_GET_LIST_BUS_NOT_CHECK_IN_FAILED =
  'MASTER_GET_LIST_BUS_NOT_CHECK_IN_FAILED'

/** get list
 * @param {object} payload
 * @param {function} onSuccess Callback function on success
 * @param {function} onError Callback function on error
 */
function actGetListBusNotCheckIn(payload, onSuccess, onError) {
  return {
    type: MASTER_GET_LIST_BUS_NOT_CHECK_IN,
    payload: payload,
    onSuccess: onSuccess,
    onError: onError,
  }
}

export function actGetListBusNotCheckInSuccess(payload) {
  return {
    type: MASTER_GET_LIST_BUS_NOT_CHECK_IN_SUCCESS,
    payload: payload,
  }
}

export function actGetListBusNotCheckInFailed() {
  return {
    type: MASTER_GET_LIST_BUS_NOT_CHECK_IN_FAILED,
  }
}



export default {
  actGetListBusNotCheckIn,
  actGetListBusNotCheckInSuccess,
  actGetListBusNotCheckInFailed,
}
