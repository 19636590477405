import { useMemo } from 'react'

import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import act from '~/modules/master/redux/actions/report-bus-not-check-in'

const useReportBusNotCheckIn = () => {
  const data = useSelector((state) => get(state, 'master.reportBusNotCheckIn'))

  const dispatch = useDispatch()
  const actions = useMemo(() => bindActionCreators(act, dispatch), [dispatch])
  return {
    actions,
    data,
  }
}

export default useReportBusNotCheckIn
