import { Box, Grid } from '@mui/material'
import { Form } from 'formik'

import {
  TEXTFIELD_ALLOW,
  TEXTFIELD_REQUIRED_LENGTH,
} from '~/common/constants'
import Button from '~/components/Button'
import { Field } from '~/components/Formik'
import Loading from '~/components/Loading'

const FormInput = ({ t, isLoading, onClose }) => {
  return (
    <Form>
      <Grid
        container
        rowSpacing={4 / 3}
        sx={{ padding: 3, backgroundColor: '#EFF5FB' }}
        justifyContent="space-between"
      >
        <Grid item lg={12} xs={12}>
          <Field.TextField
            name="reason"
            placeholder={t('RoutePointRegister.reason')}
            label={t('RoutePointRegister.reason')}
            inputProps={{
              maxLength: TEXTFIELD_REQUIRED_LENGTH.COMMON.MAX,
            }}
            allow={TEXTFIELD_ALLOW.ALPHANUMERIC}
            required
          />
        </Grid>
        
      </Grid>
      <Box >
        <Grid item xs={12} sx={{ mt: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="button"
              sx={{ marginRight: '10px' }}
              color="grayF4"
              onClick={onClose}
            >
              {t('actionBar.cancel')}
            </Button>
            <Button type="submit" sx={{ marginRight: '10px' }}>
              {t(`save`)}
            </Button>
          </Box>
        </Grid>
      </Box>
      <Loading open={isLoading}></Loading>
    </Form>
  )
}

export default FormInput
