import { combineReducers } from 'redux'

import {
  MASTER_GET_LIST_VEHICLE_REGISTER,
  MASTER_GET_LIST_VEHICLE_REGISTER_SUCCESS,
  MASTER_GET_LIST_VEHICLE_REGISTER_FAILED
} from '../actions/vehicle-register'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listVehicleRegister(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_VEHICLE_REGISTER:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_VEHICLE_REGISTER_SUCCESS:
      const { data, meta } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total: meta.total,
        totalCanceled: meta.countCanceled,
        totalRegistered: meta.countRegistered,
        page: meta.page,
      }
    case MASTER_GET_LIST_VEHICLE_REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}


export default combineReducers({
  listVehicleRegister,
})
