import { call, put, takeLatest } from 'redux-saga/effects'

import { NOTIFICATION_TYPE } from '~/common/constants'
import addNotification from '~/utils/toast'

import {
  MASTER_CANCEL_REGISTER_ROUTE,
  actCancelRegisterRouteSuccess,
  actCancelRegisterRouteFailed,
} from '../../actions/profile-custom'
import { apiCancelRegisterRoute } from '../../apis/profile-custom'

/**
 * Handle get data request and response
 * @param {object} action
 */
function* doSync(action) {
  try {
    const response = yield call(apiCancelRegisterRoute, action?.payload)

    if (response?.statusCode === 200) {
      if (action.onSuccess) {
        yield action.onSuccess()
      }
      yield put(actCancelRegisterRouteSuccess())
      addNotification(
        'profileCustom.notification.cancelRegisRouteSuccess',
        NOTIFICATION_TYPE.SUCCESS,
      )
    } else {
      yield put(actCancelRegisterRouteFailed())
      addNotification(
        response?.message || 'profileCustom.notification.cancelRegisRouteFail',
        NOTIFICATION_TYPE.ERROR,
      )
      throw new Error(response?.message)
    }
  } catch (error) {
    // Call callback action if provided
    if (action.onError) {
      yield action.onError(error)
    }
  }
}

export default function* watchSync() {
  yield takeLatest(MASTER_CANCEL_REGISTER_ROUTE, doSync)
}
