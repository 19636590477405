export const ROUTE = {
  EMPLOYEE: {
    TITLE: 'employee',
    PROFILE_CUSTOM: {
      LIST: {
        PATH: '/profile-custom',
        TITLE: 'profileCustom',
      },
      DETAIL: {
        PATH: '/profile-custom/:id/detail',
        TITLE: 'employeeDetail',
      },
      UPDATE: {
        PATH: '/profile-custom/:id/update',
        TITLE: 'employeeUpdate',
      },
    },
    CAT_SHIFT: {
      LIST: {
        PATH: '/cat-shift',
        TITLE: 'catShift',
      },
      DETAIL: {
        PATH: '/cat-shift/:id/detail',
        TITLE: 'workShiftDetail',
      },
    },
    ORG_STRUCTURE: {
      LIST: {
        PATH: '/org-structure',
        TITLE: 'orgStructure',
      },
    },
    FACTORY: {
      LIST: {
        PATH: '/factory',
        TITLE: 'factory',
      },
    },
    POSITION: {
      LIST: {
        PATH: '/position',
        TITLE: 'position',
      },
    },
  },
  PICKUP_POINT: {
    LIST: {
      PATH: '/pickup-point',
      TITLE: 'pickupPoint',
    },
  },
  ROUTE: {
    LIST: {
      PATH: '/route',
      TITLE: 'route',
    },
    CREATE: {
      PATH: '/route/create',
      TITLE: 'routeCreate',
    },
    DETAIL: {
      PATH: '/route/:id/detail',
      TITLE: 'routeDetail',
    },
    UPDATE: {
      PATH: '/route/:id/update',
      TITLE: 'routeUpdate',
    },
  },
  ATTENDANCE: {
    LIST: {
      PATH: '/attendance',
      TITLE: 'attendance',
    },
  },
  ATTENDANCE_NOT_EXCLUDING_HOLIDAY: {
    LIST: {
      PATH: '/attendance-not-excluding-holiday',
      TITLE: 'attendanceNotExcludingHoliday',
    },
  },
  VEHICLE_CATEGORIES: {
    LIST: {
      PATH: '/vehicle-categories',
      TITLE: 'vehicleCategories',
    },
  },
  REGISTER_VEHICLE: {
    LIST: {
      PATH: '/vehicle-regis',
      TITLE: 'vehicleRegister',
    },
  },
  REGISTER_ROUTE_POINT: {
    LIST: {
      PATH: '/route-point-regis',
      TITLE: 'routePointRegister',
    },
  },
  REPORT: {
    TITLE: 'report',
    REPORT_BR11: {
      LIST: {
        PATH: '/report-br11',
        TITLE: 'reportBr11',
      },
    },
    REPORT_ALL: {
      LIST: {
        PATH: '/report-all',
        TITLE: 'reportAll',
      },
    },
    REPORT_OT: {
      LIST: {
        PATH: '/report-ot-business-travel',
        TITLE: 'reportOt',
      },
    },
    REPORT_FEE: {
      LIST: {
        TITLE: 'reportFee',
        PATH: '/report-fee',
      },
    },
    REPORT_CHECK_IN_EMPLOYEE: {
      LIST: {
        TITLE: 'checkInEmployee',
        PATH: '/check-in-employee',
      },
    },
    REPORT_BUSINESS_TRAVEL_NOT_CHECK_IN: {
      LIST: {
        TITLE: 'businessTravelNotCheckIn',
        PATH: '/business-travel-not-check-in',
      },
    },
    REPORT_BUS_NOT_CHECK_IN: {
      LIST: {
        TITLE: 'busNotCheckIn',
        PATH: '/bus-not-check-in',
      },
    },
  },
  SETTING: {
    TITLE: 'setting',
    SYNC: {
      TITLE: 'settingSync',
      PATH: '/setting-sync',
    },
    FEE: {
      TITLE: 'settingFee',
      PATH: '/setting-fee',
    },
    AUTO_APPROVAL: {
      TITLE: 'settingAutoApproval',
      PATH: '/setting-auto-approval',
    },
  },
}
