import { combineReducers } from 'redux'

import {
  MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
  MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED,
  MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS,
  MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY,
  MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED,
  MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_RESET,
  MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS,
} from '../actions/attendance-not-excluding-holiday'

/**
 * Sample reducer
 * @param {object} state
 * @param {object} action
 * @returns
 */
function listAttendanceNotExcludingHoliday(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY:
      return { ...state, isLoading: true }
    case MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS:
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        total: data.meta.totalRosterCalendar,
        data: data.items || [],
      }
    case MASTER_GET_LIST_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

function syncAttendanceNotExcludingHoliday(
  state = {
    isLoading: false,
    data: [],
  },
  action,
) {
  switch (action.type) {
    case MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY:
      return { ...state, isLoading: true }
    case MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_SUCCESS:
      const { data, total } = action.payload
      return {
        ...state,
        isLoading: false,
        data,
        total,
      }
    case MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_FAILED:
      return {
        ...state,
        isLoading: false,
      }
    case MASTER_SYNC_ATTENDANCE_NOT_EXCLUDING_HOLIDAY_RESET:
      return {
        isLoading: false,
        data: [],
      }
    default:
      return state
  }
}

export default combineReducers({
  listAttendanceNotExcludingHoliday,
  syncAttendanceNotExcludingHoliday,
})
